import { BaseError } from "./base-error.interface";

export class SystemRuntimeError extends BaseError implements Error {
    constructor(message?: string) {
        super(true);
        this._name = 'SystemRuntimeError';
        
        if (message) {
            this._message = message;
        }
    }
}