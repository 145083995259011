import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { ApiSuffixConstant } from 'src/api/constants/api-suffix.constant';
import { LoadingConstant } from '../../infrastructure/constants/loading.constant';
import { HttpStatus } from '../enums/http-status.enum';
import { LoadingService } from '../services/loading.service';

/**
 * This class is for intercepting http requests. When a request starts, we set the loadingSub property
 * in the LoadingService to true. Once the request completes and we have a response, set the loadingSub
 * property to false. If an error occurs while servicing the request, set the loadingSub property to false.
 * @class {HttpRequestInterceptor}
 */
@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  constructor(private readonly loadingService: LoadingService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let isLoadingVisibled = false;

    if (request.headers.has(LoadingConstant.LOADING_HEADER_NAME)) {
      request = request.clone({
        headers: request.headers.delete(LoadingConstant.LOADING_HEADER_NAME, LoadingConstant.LOADING_HEADER_VALUE),
      });
    } else {
      this.loadingService.setLoading(true, request.url);
    }
    return next.handle(request).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          if (evt != null) {
            // close loading
            this.loadingService.setLoading(false, request.url);
          }
        }
      }),
      catchError(error => {
        isLoadingVisibled = error instanceof HttpErrorResponse && error.status === HttpStatus.NotAuthorized;

        return throwError(() => error);
      }),
      finalize(() => {
        if (this.hasExcludesLoading(isLoadingVisibled, request.url)) {
          this.loadingService.setStateLoading(false);

          this.loadingService.clearLoadingMap();
        } else {
          this.loadingService.setLoading(isLoadingVisibled, request.url);
        }
      })
    );
  }

  // This function is for checking whether the Loading animation should be continued or not
  private hasExcludesLoading(status: boolean, requestUrl: string): boolean {
    if (!status) {
      return false;
    }

    return FORCE_EXCLUDE_LOADING.some(ele => requestUrl.includes(ele));
  }
}

// Only use to force the Loading animation to false due to the 401 error is returned by REFRESH_TOKEN and FORCE_LOGOUT APIs
const FORCE_EXCLUDE_LOADING = [ApiSuffixConstant.REFRESH_TOKEN, ApiSuffixConstant.FORCE_LOGOUT];

export const LoadingInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true }];
