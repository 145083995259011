export class AppRoutingConstant {
  /** Prefix */
  public static readonly LIST = 'list';
  public static readonly DETAIL = 'detail';
  public static readonly ACTION = 'action';
  public static readonly CREATE = 'create';
  public static readonly UPDATE = 'update';
  public static readonly SUMMARY = 'summary';

  /** ========================================================= */

  public static readonly HOME = [''];
  public static readonly LOGIN = ['login'];
  public static readonly REGISTER = ['register'];
  public static readonly DASHBOARD = ['dashboard'];
  public static readonly MY_PROFILE = ['my-profile'];

  /* ----------------------------------------- */
  /*              ORGANIZATION                 */
  /* ----------------------------------------- */
  public static readonly ORG = ['org'];
  public static readonly ORG_LIST = AppRoutingConstant.ORG.concat(AppRoutingConstant.LIST);

  /* ----------------------------------------- */
  /*                  GROUP                    */
  /* ----------------------------------------- */
  public static readonly GROUP = ['group'];
  public static readonly GROUP_LIST = AppRoutingConstant.GROUP.concat(AppRoutingConstant.LIST);

  /* ----------------------------------------- */
  /*          EVALUATION_UNIT              */
  /* ----------------------------------------- */

  public static readonly EVALUATION_UNIT = ['evaluation-unit'];
  public static readonly EVALUATION_UNIT_LIST = AppRoutingConstant.EVALUATION_UNIT.concat(AppRoutingConstant.LIST);
  public static readonly EVALUATION_UNIT_ACTION = AppRoutingConstant.EVALUATION_UNIT.concat(AppRoutingConstant.ACTION);

  /* ----------------------------------------- */
  /*          EVALUATION_TEMPLATE              */
  /* ----------------------------------------- */

  public static readonly EVALUATION_TEMPLATE = ['evaluation-template'];
  public static readonly EVALUATION_LIST = AppRoutingConstant.EVALUATION_TEMPLATE.concat(AppRoutingConstant.LIST);
  public static readonly EVALUATION_ACTION = AppRoutingConstant.EVALUATION_TEMPLATE.concat(AppRoutingConstant.ACTION);

  // TODO: will delete later
  /* ----------------------------------------- */
  /*          EVALUATION_REGISTER              */
  /* ----------------------------------------- */
  public static readonly EVALUATION_REGISTER = ['evaluation-register'];

  /* ----------------------------------------- */
  /*                  USER                     */
  /* ----------------------------------------- */
  public static readonly USER = ['user'];
  public static readonly USER_LIST = AppRoutingConstant.USER.concat(AppRoutingConstant.LIST);

  /* ----------------------------------------- */
  /*                  ERROR                    */
  /* ----------------------------------------- */
  public static readonly ERROR = ['error'];

  /* ----------------------------------------- */
  /*          EVALUATION_RESULT                */
  /* ----------------------------------------- */
  public static readonly EVALUATION_RESULT = ['evaluation-result'];

  /* ----------------------------------------- */
  /*                  MAINTENANCE                    */
  /* ----------------------------------------- */
  public static readonly MAINTENANCE = ['maintenance'];

  /* ----------------------------------------- */
  /*                  PRIVACY POLICY                    */
  /* ----------------------------------------- */
  public static readonly PRIVACY_POLICY = ['privacy-policy'];

  /* ----------------------------------------- */
  /*                  TERMS OF SERVICE                    */
  /* ----------------------------------------- */
  public static readonly TERMS_OF_SERVICE = ['terms-of-service'];

  /* ----------------------------------------- */
  /*                  FORGOT PASSWORD          */
  /* ----------------------------------------- */
  public static readonly FORGOT_PASSWORD = ['forgot-password'];

  /* ----------------------------------------- */
  /*                  RESET PASSWORD           */
  /* ----------------------------------------- */
  public static readonly RESET_PASSWORD = ['reset-password'];

  /* ----------------------------------------- */
  /*                  SUMMARY_SETTING           */
  /* ----------------------------------------- */
  public static readonly SUMMARY_SETTING = ['summary-setting'];

  /* ----------------------------------------- */
  /*                  EVALUATION_EVENT_SCHEDULE*/
  /* ----------------------------------------- */
  public static readonly EVALUATION_EVENT_SCHEDULE = ['evaluation-event-schedule'];

  /* ----------------------------------------- */
  /*          EVENT_EVALUATION                 */
  /* ----------------------------------------- */
  public static readonly EVENT_EVALUATION = ['event-evaluation'];
}
