import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { SidebarItem } from '../enums/sidebar.enum';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private _activeItem$ = new BehaviorSubject<any>(undefined);
  private _isExpandSidebar$ = new BehaviorSubject<boolean>(true);
  private _isExpandOnHoverSidebar$ = new Subject<boolean>();
  private _isSidebarVisible = new BehaviorSubject<boolean>(false);
  private _clickedItem$ = new Subject<any>();

  public setActiveItem(item: SidebarItem): void {
    this._activeItem$.next(item);
  }

  public getActiveItem(): Observable<SidebarItem> {
    return this._activeItem$.asObservable();
  }

  public expandSidebar(expand = true): void {
    this._isExpandSidebar$.next(expand);
  }

  public getExpandSidebarObs(): Observable<boolean> {
    return this._isExpandSidebar$.asObservable();
  }

  public expandOnHoverSidebar(expand = true): void {
    this._isExpandOnHoverSidebar$.next(expand);
  }

  public getExpandOnHoverSidebarObs(): Observable<boolean> {
    return this._isExpandOnHoverSidebar$.asObservable();
  }

  public setSidebarVisibility(isVisible: boolean): void {
    this._isSidebarVisible.next(isVisible);
  }

  public isSideBarVisibleObs(): Observable<boolean> {
    return this._isSidebarVisible.asObservable();
  }

  public setClickedItem(item: string): void {
    this._clickedItem$.next(item);
  }

  public getClickedItem(): Observable<string> {
    return this._clickedItem$.asObservable();
  }
}
