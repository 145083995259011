export enum SidebarItem {
  HOME = 'HOME',
  DASHBOARD = 'DASHBOARD',
  ORG_CHART = 'ORG_CHART',
  USER = 'USER',
  GROUP = 'GROUP',
  MEMBER = 'MEMBER',
  EVALUATION_UNIT = 'EVALUATION_UNIT',
  EVALUATION_TEMPLATE = 'EVALUATION_TEMPLATE',
  EVALUATION_REGISTER = 'EVALUATION_REGISTER',
  EVALUATION_RESULT = 'EVALUATION_RESULT',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
  SUMMARY_SETTING = 'SUMMARY_SETTING',
  EVALUATION_EVENT_SCHEDULE = 'EVALUATION_EVENT_SCHEDULE',
  EVENT_EVALUATION = 'EVENT_EVALUATION',
}
