import { ChangeDetectionStrategy, Component, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { AnimationConstant } from 'src/app/infrastructure/constants/animation.constant';

@Component({
  selector: 'app-new-lottie',
  templateUrl: './new-lottie.component.html',
  styleUrls: ['./new-lottie.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewLottieComponent {
  @ViewChild('lottie') public base: LottieComponent;

  @Input() options: AnimationOptions | null;

  @Input() styles: Partial<CSSStyleDeclaration> | null = AnimationConstant.DEFAULT_STYLES();

  @Output() animationEnd: EventEmitter<any> = new EventEmitter();
  @Output() domLoaded: EventEmitter<any> = new EventEmitter();

  private animationItem: AnimationItem;

  constructor(private ngZone: NgZone) {}

  public animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
  }

  public onComplete($event: any): void {
    this.animationEnd.emit($event);
  }

  public onDomLoaded(): void {
    this.domLoaded.emit();
  }

  public stop(): void {
    this.ngZone.runOutsideAngular((): void => {
      this.animationItem.stop();
    });
  }

  public play(): void {
    this.ngZone.runOutsideAngular((): void => {
      this.animationItem.play();
    });
  }
}
