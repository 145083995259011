import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { BaseComponent } from 'src/app/infrastructure/components/base-component/base.component';
import { SidebarItem } from 'src/app/infrastructure/enums/sidebar.enum';
import { Theme } from 'src/app/infrastructure/enums/theme.enum';
import { SidebarGroup } from 'src/app/infrastructure/interfaces/sidebar.interface';
import { SidebarService } from 'src/app/infrastructure/services/sidebar.service';
import { ThemeService } from 'src/app/infrastructure/services/theme.service';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpansionPanelComponent extends BaseComponent {
  @Input()
  sideBarGroup: SidebarGroup;

  @Input()
  index: number;

  public activeItem: string;
  public _isExpand: boolean = true;
  public isDark: boolean = false;
  public panelOpenState: boolean = true;

  constructor(
    private readonly sidebarService: SidebarService,
    private readonly themeService: ThemeService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super();
  }

  public open() {
    this.panelOpenState = true;
  }

  public close() {
    this.panelOpenState = false;
  }

  public onClick(url: string) {
    this.sidebarService.setClickedItem(url);
  }

  protected override onInit(): void {
    if (this.sideBarGroup.labelGroup && this.sideBarGroup.items.length > 0) {
      this.initExpand();
    }
    this.initActiveItem();
    this.initTheme();
  }

  private initActiveItem(): void {
    this.subscribe(this.sidebarService.getActiveItem(), (activeItem: SidebarItem) => {
      this.activeItem = activeItem;
      this.changeDetectorRef.detectChanges();
    });
  }

  private initExpand(): void {
    this.subscribe(this.sidebarService.getExpandSidebarObs(), isExpand => {
      this._isExpand = isExpand;
      this.handleShowIcon();

      this.changeDetectorRef.detectChanges();
    });
  }

  private initTheme(): void {
    this.subscribe(this.themeService.getTheme(), (theme: Theme) => {
      this.isDark = theme === Theme.DARK;
      this.changeDetectorRef.detectChanges();
    });
  }

  private handleShowIcon(): void {
    if (!this._isExpand && !this.panelOpenState) {
      this.open();
    }
  }
}
