import { AnimationOptions } from 'ngx-lottie';
export class AnimationConstant {
  public static LOADING(isDark: boolean): AnimationOptions {
    const animation = {
      path: `assets/images/loading/loading_${isDark ? 'dark' : 'light'}.json`,
      autoplay: true,
      loop: true,
    };
    return animation;
  }

  // Default custom style for global loading
  public static LOADING_STYLES(): Partial<CSSStyleDeclaration> {
    const styles: Partial<CSSStyleDeclaration> = {
      width: '10rem',
      height: '10rem',
    };
    return styles;
  }

  // Default custome style for all component using lottie
  public static DEFAULT_STYLES(): Partial<CSSStyleDeclaration> {
    const styles: Partial<CSSStyleDeclaration> = {
      width: '4rem',
      height: '4rem',
    };
    return styles;
  }
}
