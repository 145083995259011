import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  private sessionStorage!: Storage;
  private isEnabled = false;

  constructor(@Inject(PLATFORM_ID) private readonly platformId: Object) {
    if (!window.sessionStorage) {
      this.isEnabled = false;
      console.error('Current browser does not support Session Storage');
      return;
    }
    this.isEnabled = true;
    this.sessionStorage = window.sessionStorage;
  }

  public setItem(key: string, value: string): void {
    if (isPlatformBrowser(this.platformId) && this.isEnabled) {
      this.sessionStorage.setItem(key, value);
    }
  }

  public getItem(key: string): string | null {
    if (!isPlatformBrowser(this.platformId) && !this.isEnabled) {
      return null;
    }

    return this.sessionStorage.getItem(key) || null;
  }

  public removeItem(key: string): void {
    if (isPlatformBrowser(this.platformId) && this.isEnabled) {
      this.sessionStorage.removeItem(key);
    }
  }

  public clear(): void {
    this.sessionStorage.clear();
  }

  public setObject(key: string, value: unknown): void {
    if (!isPlatformBrowser(this.platformId) && !this.isEnabled) {
      return;
    }
    this.sessionStorage.setItem(key, JSON.stringify(value));
  }

  public getObject<TType = unknown>(key: string): TType | null {
    if (!isPlatformBrowser(this.platformId) && !this.isEnabled) {
      return null;
    }
    return JSON.parse(this.sessionStorage.getItem(key) || null) as TType;
  }
}
