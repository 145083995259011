import { BaseComponent } from 'src/app/infrastructure/components/base-component/base.component';
import { Component } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { Observable } from 'rxjs';
import { Breadcrumb } from '../../interfaces/breadcrumb.interface';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent extends BaseComponent {
  public breadcrumbs$: Observable<Breadcrumb[]>;

  constructor(private readonly breadcrumbService: BreadcrumbService) {
    super();
  }

  public onClick(url: string): void {
    this.breadcrumbService.setClickedItem(url);
  }

  protected override onInit(): void {
    this.breadcrumbs$ = this.breadcrumbService.getBreadcrumbs();
  }
}
