import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicMenuListComponent } from './components/dynamic-menu-list.component';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [DynamicMenuListComponent],
  imports: [CommonModule, MatMenuModule],
  exports: [DynamicMenuListComponent],
})
export class DynamicMenuListModule {}
