import { NavigationEnd, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { filter, takeUntil } from 'rxjs';
import { Subscribable } from '../components/base-component/subscribable';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

const GA_TRACKING_ID = environment?.GA_TRACKING_ID;

@Injectable({ providedIn: 'root' })
export class AnalyticsService extends Subscribable {
  private _gaTrackingId = GA_TRACKING_ID;

  constructor(private readonly router: Router) {
    super();
  }

  public addGAScript(): void {
    if (this._gaTrackingId) {
      let gtagScript: HTMLScriptElement = document.createElement('script');

      gtagScript.async = true;

      gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + this._gaTrackingId;

      document.head.append(gtagScript);

      /** Disable automatic page view hit to fix duplicate page view count  **/
      gtag('config', this._gaTrackingId, { send_page_view: false });
    }
  }

  public setUpAnalytics(): void {
    if (this._gaTrackingId) {
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          takeUntil(this.onDestroySubject)
        )
        .subscribe(event => {
          gtag('config', this._gaTrackingId, {
            page_path: (event as NavigationEnd).urlAfterRedirects,
          });
        });
    }
  }
}
