<section class="sidebar" [class.sidebar--small]="!isExpand">
  <div class="sidebar--inner-scroll">
    <ng-template [ngIf]="logo">
      <div class="sidebar__logo-container">
        <div class="sidebar__logo">
          <a [routerLink]="logo.routerLink">
            <img *ngIf="logo.src && logo.srcSmall" [src]="isExpand ? logo.src : logo.srcSmall" />
            <i *ngIf="logo.icon && logo.iconSmall" [ngClass]="isExpand ? logo.icon : logo.iconSmall"></i>
          </a>
        </div>
        <div *ngIf="isExpand" class="sidebar__role" [class.sidebar__role-member]="isMember">
          <span *ngIf="!isMember && !hideRoleName">{{ roleName }}</span>
        </div>
      </div>
    </ng-template>
    <ng-content select="[header]"></ng-content>

    <ul>
      <ng-container *ngFor="let sidebarGroup of sidebarGroups; let i = index">
        <app-expansion-panel [sideBarGroup]="sidebarGroup" [index]="i"></app-expansion-panel>
      </ng-container>

      <ng-content select="[content]"></ng-content>

      <li class="side--bar__action" [class.expanded]="isExpand" [class.collapse]="!isExpand">
        <i
          class="toggle--item clicked icon-arrow_right"
          (click)="onToggleExpand()"
          [class.icon--collapse]="isExpand"></i>
      </li>
    </ul>

    <ng-content select="[footer]"></ng-content>
  </div>
</section>
