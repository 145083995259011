import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { ExpansionPanelComponent } from './components/expansion-panel.component';
import { ExpansionPanelService } from './services/expansion-panel.service';

@NgModule({
  declarations: [ExpansionPanelComponent],
  imports: [CommonModule, MatExpansionModule, RouterModule],
  exports: [ExpansionPanelComponent],
  providers: [ExpansionPanelService],
})
export class ExpansionPanelModule {}
