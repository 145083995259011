<section class="dynamic--menu__section" [class.theme--dark]="isDark">
  <i
    class="clicked toolbar--icon"
    [class]="customTriggerOpenIcon"
    [class.has--open]="hasOpenMenu"
    [matMenuTriggerFor]="menu"
    (menuOpened)="menuOpened()"
    #menuTrigger></i>
  <mat-menu #menu="matMenu" class="dynamic--menu__panel" (closed)="menuClosed()">
    <div
      mat-menu-item
      *ngFor="let item of menuListItems"
      [disabled]="item.isDisabled"
      (click)="onClick(item)"
      [disableRipple]="true">
      <i *ngIf="item?.menuIcon" [ngClass]="item.menuIcon"></i>
      <p>{{ item.menuText }}</p>
    </div>
    <ng-content customMenu select="[customMenu]"></ng-content>
  </mat-menu>
</section>
