import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of, catchError, BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { BaseApiResponse } from 'src/api/interfaces/base-api-response.interface';
import { ApiAuthService } from 'src/api/services/api-auth.service';
import { AuthTokenStorageService } from '../../services/auth-token-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public isLoggedOut$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly apiAuthService: ApiAuthService,
    private readonly authTokenService: AuthTokenStorageService
  ) {}

  public logout(): Observable<BaseApiResponse> {
    this.isLoggedOut$.next(true);

    return this.apiAuthService.logout().pipe(
      catchError(() => {
        return of(null);
      }),
      finalize(() => {
        this.authTokenService.clearCredentials();
      })
    );
  }

  public isLoggedIn(): Promise<boolean> {
    if (!this.authTokenService.getToken()) {
      return firstValueFrom(this.apiAuthService.ping())
        .then(() => true)
        .catch(() => false);
    }

    return Promise.resolve(true);
  }
}
