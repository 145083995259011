import { delay } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private loadingMap: Map<string, boolean> = new Map<string, boolean>();

  public getLoadingObservable(): Observable<boolean> {
    return this._loading$.asObservable().pipe(delay(0));
  }

  public setStateLoading(state: boolean): void {
    this._loading$.next(state);
  }

  public setLoading(loading: boolean, url: string): void {
    if (!url) {
      throw new Error('The request URL must be provided to the LoadingService.setLoading function');
    }
    if (loading) {
      this.loadingMap.set(url, loading);
      this.setStateLoading(true);
    } else if (!loading && this.loadingMap.has(url)) {
      this.loadingMap.delete(url);
    }
    if (this.loadingMap.size === 0) {
      this.setStateLoading(false);
    }
  }

  public clearLoadingMap() {
    this.loadingMap.clear();
  }
}
