import { Injectable } from '@angular/core';
import { BackUpFilter } from '../interfaces/backup-filter.interface';

export type BackUpFilterTab = {
  [key: string | number]: BackUpFilter;
};

@Injectable({ providedIn: 'root' })
export class BackupFilterService {
  private _backupFilter: BackUpFilter;

  private _backupFilterTab: BackUpFilterTab;

  constructor() {}

  public setBackupFilterData(backupData: BackUpFilter) {
    this._backupFilter = backupData;
  }

  public getBackupFilterData(): BackUpFilter {
    return this._backupFilter;
  }

  public setBackupFilterTabData(backupData: BackUpFilterTab) {
    this._backupFilterTab = backupData;
  }

  public getBackupFilterTabData(): BackUpFilterTab {
    return this._backupFilterTab;
  }

  public getBackupFilterTabPerKey(key: string | number): BackUpFilter {
    if (this._backupFilterTab) {
      const backup = this.getBackupFilterTabData()[key];

      if (backup?.filter || backup?.input) {
        return backup;
      }
    }

    return undefined;
  }
}
