<header [class.--compact]="!isExpandSidebar">
  <app-breadcrumb></app-breadcrumb>
  <section *ngIf="isShowRightMenuSection" class="menu-nav-bar__section">
    <app-dynamic-menu-list customTriggerOpenIcon="icon-help" [menuListItems]="menuListItems"> </app-dynamic-menu-list>
    <ng-container *ngTemplateOutlet="menuProfile"></ng-container>
  </section>
</header>

<ng-template #menuProfile>
  <section class="profile--menu__section">
    <img [src]="avatarUrl" [matMenuTriggerFor]="menu" #menuTrigger />
    <mat-menu #menu="matMenu" class="dynamic--menu__panel">
      <div *ngIf="!isServiceAdmin()" mat-menu-item [disableRipple]="true" (click)="gotoMyProfile()">
        <i class="icon-user_inactive"></i>
        <p>{{ MessageUtils.MyProfile.Key }}</p>
      </div>
      <div mat-menu-item [disableRipple]="true" (click)="onLogout()">
        <i class="icon-logout"></i>
        <p>{{ MessageUtils.Global.Logout }}</p>
      </div>
    </mat-menu>
  </section>
</ng-template>
