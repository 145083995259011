import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResetPassWordRequest } from 'src/app/infrastructure/interfaces/common.interface';
import { RegistrationEvaluationInput } from 'src/app/pages/evaluation-register/model/evaluation-register-list.interface';
import { GroupListByUser, GroupListByUserInput } from 'src/app/pages/user/interfaces/user-detail.interface';
import { User } from '../../app/infrastructure/interfaces/user.interface';
import {
  ImportUserModel,
  SuggestUserListRequest,
  UserListRequest,
  UserListResponse,
} from '../../app/pages/user/interfaces/user-list.interface';
import { BaseApiService, BASE_FEATURE_URL, OPTIONS_DISABLE_LOADING } from '../base-api.service';
import { BaseApiResponse } from '../interfaces/base-api-response.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiUserService extends BaseApiService {
  constructor(http: HttpClient) {
    super(http, BASE_FEATURE_URL.USER);
  }

  public getMyProfile(showLoading: boolean = false): Observable<User> {
    return this.get(`/my-profile`, null, showLoading ? null : OPTIONS_DISABLE_LOADING);
  }

  public getListUser(input: UserListRequest): Observable<UserListResponse> {
    return this.get(`/list-by-filter`, input);
  }

  public create(input: any): Observable<BaseApiResponse> {
    return this.post(`/create`, input);
  }

  public update(input: any, id: string): Observable<BaseApiResponse> {
    const params = { ...input, user_id: id };

    return this.put(`/update`, params);
  }

  public getDetail(id: string, showLoading: boolean = true): Observable<User> {
    return this.get(`/detail`, { user_id: id }, showLoading ? null : OPTIONS_DISABLE_LOADING);
  }

  public remove(id: string): Observable<BaseApiResponse> {
    return this.delete(`/delete`, { user_id: id });
  }

  public getSuggestedUsers(input: SuggestUserListRequest): Observable<UserListResponse> {
    return this.get(`/list-by-search-for-group`, input);
  }

  public getBelongGroups(input: GroupListByUserInput): Observable<GroupListByUser> {
    return this.get(`/belong-groups`, input);
  }

  public removeFromGroup(input: GroupListByUserInput): Observable<GroupListByUser> {
    return this.delete(`/remove-from-group`, input);
  }

  public addToGroups(input: { user_id: string; org_id: string; grp_ids: string[] }): Observable<BaseApiResponse> {
    return this.post(`/add-to-groups`, input);
  }

  public getEvaluationMembers(input: { grp_id: string; keyword: string }): Observable<UserListResponse> {
    return this.get(`/all-assigned-to-group`, input);
  }

  public registrationEvaluation(input: RegistrationEvaluationInput[]): Observable<BaseApiResponse> {
    return this.post(`/evaluation`, input);
  }

  public resetPassword(input: ResetPassWordRequest): Observable<BaseApiResponse> {
    return this.post(`/reset-password`, input);
  }

  public importUsers(input: ImportUserModel): Observable<ImportUserModel> {
    return this.post(`/import-user`, input);
  }
}
