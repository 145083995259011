import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutingConstant } from '../constants/app-routing.constant';
import { SideMenuSection, SiteItemsByRole, SiteItems_Help } from '../constants/role.constant';
import { SessionStorageConstant } from '../constants/session-storage.constant';
import { SidebarGroup, SidebarLogo } from '../interfaces/sidebar.interface';
import { SessionStorageService } from './session-storage.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class MainService {
  constructor(
    private readonly userService: UserService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly router: Router
  ) {}

  public getSideBarLogo(): SidebarLogo {
    const logo: SidebarLogo = {
      icon: `icon-logo_full`,
      iconSmall: `icon-logo_icon`,
      routerLink: '/',
    };
    return logo;
  }

  public getSidebarGroups(): SidebarGroup[] {
    if (this.isPrivacyTermsUrl()) {
      // Fixed Sidebar for: Policy privacy, Terms of service page
      return [
        {
          labelGroup: '',
          items: [SiteItems_Help.PRIVACY_POLICY, SiteItems_Help.TERMS_OF_SERVICE],
        },
      ];
    }

    const roleByUserProfile = this.userService.getUserProfile()?.role_id;
    const sessionStorageRoleIdValue = this.sessionStorageService.getItem(SessionStorageConstant.ROLE_ID);
    const roleBySessionStorage = sessionStorageRoleIdValue ? Number(sessionStorageRoleIdValue) : undefined;
    const role_id = roleByUserProfile | roleBySessionStorage;

    let sideMenuRole: SidebarGroup[] = [];

    const siteItemByRole = SiteItemsByRole[role_id];

    if (siteItemByRole) {
      const siteItems: any[] = siteItemByRole[`items`];
      sideMenuRole = siteItems
        .reduce((prev, cur) => {
          const { sectionId, ...other } = cur;
          prev[sectionId] = [
            ...(prev[sectionId] || []),
            {
              ...other,
            },
          ];

          return prev || {};
        }, [])
        .filter((ele: any) => ele)
        .map((value: any, index: number) => {
          return { labelGroup: SideMenuSection[index] || '', items: value };
        });
    }

    return sideMenuRole;
  }

  public isPrivacyTermsUrl(): boolean {
    return [`/${AppRoutingConstant.PRIVACY_POLICY[0]}`, `/${AppRoutingConstant.TERMS_OF_SERVICE[0]}`].includes(
      this.router.routerState.snapshot.url
    );
  }
}
