import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map, take, tap } from 'rxjs/operators';
import { ApiUserService } from 'src/api/services/api-user.service';
import { AssetConstant } from 'src/app/infrastructure/constants/asset.constant';
import { OrganizationItem } from 'src/app/pages/organization/model/organization.interface';
import { Subscribable } from '../components/base-component/subscribable';
import { SessionStorageConstant } from '../constants/session-storage.constant';
import { AccountType, User } from '../interfaces/user.interface';
import { MessageUtils } from './../utils/message.utils';
import { SessionStorageService } from './session-storage.service';

@Injectable({ providedIn: 'root' })
export class UserService extends Subscribable {
  private _userProfile: User;
  private readonly _roleChange$ = new Subject<string | AccountType>();
  
  constructor(private readonly apiUserService: ApiUserService,
    private readonly sessionStorageService: SessionStorageService) {
    super();
  }

  public get avatarUrl(): string {
    let avatar_url = this._userProfile?.avatar_url;
    // backup avatar url and restore it when error page display and lost info
    if (this._userProfile && this._userProfile?.avatar_url) {
      this.sessionStorageService.setItem(SessionStorageConstant.AVATAR, this._userProfile?.avatar_url);
    } else {
      avatar_url = this.sessionStorageService.getItem(SessionStorageConstant.AVATAR);
    }
    
    return avatar_url ? avatar_url : AssetConstant.AVATAR_DEFAULT_URL;
  }

  public getMyProfile(): Observable<User> {
    if (!this._userProfile) {
      return this.apiUserService.getMyProfile().pipe(
        tap((user: User) => {
          this._userProfile = user;
        })
      );
    }
    return of(this._userProfile);
  }

  public getUserProfile(): User {
    return this._userProfile;
  }

  public setUserProfile(userProfile: User): void {
    this._userProfile = userProfile;
  }

  public hasPermission$(permissionData: AccountType[]): Observable<boolean> {
    if (permissionData.length === 0) {
      return of(true);
    }

    return this.getMyProfile().pipe(
      map((user: User) => this.isPermission(permissionData, user)),
      take(1)
    );
  }

  public isServiceAdmin(currentUser?: User): boolean {
    const user = currentUser ? currentUser : this._userProfile;
    return user?.role_id === AccountType.SERVICE_ADMIN;
  }

  public isOrgAdmin(currentUser?: User): boolean {
    const user = currentUser ? currentUser : this._userProfile;
    return user.role_id === AccountType.ORGANIZATION_ADMIN;
  }

  public isGroupAdmin(currentUser?: User): boolean {
    const user = currentUser ? currentUser : this._userProfile;
    return user.role_id === AccountType.GROUP_ADMIN;
  }

  public isMember(currentUser?: User): boolean {
    const user = currentUser ? currentUser : this._userProfile;
    return user.role_id === AccountType.MEMBER;
  }

  public isRoleNotOrg(currentUser?: User): boolean {
    const user = currentUser ? currentUser : this._userProfile;
    return user.role_id === AccountType.SERVICE_ADMIN || user.role_id === AccountType.ORGANIZATION_ADMIN;
  }

  public getRoleId(currentUser?: User): AccountType {
    const user = currentUser ? currentUser : this._userProfile;
    return user.role_id;
  }

  public getRoleName(isShowMember?: boolean, currentUser?: User): string {
    const user = currentUser ? currentUser : this._userProfile;
    switch (user.role_id) {
      case AccountType.SERVICE_ADMIN:
        return MessageUtils.Global.ServiceAdmin;
      case AccountType.ORGANIZATION_ADMIN:
        return MessageUtils.Global.OrganizationAdmin;
      case AccountType.GROUP_ADMIN:
        return MessageUtils.Global.GroupAdmin;
      case AccountType.MEMBER:
        return isShowMember ? '' : MessageUtils.Global.Member;
    }
  }

  public getManagedGroupId(): string {
    if (this.isGroupAdmin()) {
      return this.getUserProfile().belong_to?.grp_ids?.[0];
    }
    return null;
  }

  public getBelongToOrgId(): OrganizationItem['org_id'] {
    return this._userProfile?.belong_to?.org_id || null;
  }

  private isPermission(permissionData: AccountType[], user: User): boolean {
    return permissionData.some(x => x === user.role_id);
  }

  public getRoleChangeObservable(): Observable<string | AccountType> {
    return this._roleChange$;
  }

  public setRoleValue(roleChange: string | AccountType) {
    this._roleChange$.next(roleChange);
  }
}
