import { NodeType } from 'src/app/modules/unit-tree/enums/unit-tree.enums';
import { SummaryType } from 'src/app/pages/evaluation-template/model/evaluation-template-form.enum';
import { environment } from 'src/environments/environment';

export const imgMaxSizeUpload = environment?.imageUploadMaximumFileSize || 5242880;
const imgMaxSizeUpload_MiB = imgMaxSizeUpload / Math.pow(1024, 2);
export const limitRecordCSV = 500;
export class MessageUtils {
  public static required(keyParams: string): string {
    return `${keyParams}を入力してください。`;
  }

  public static maxLength(keyParams: string, numberOfChars: number = 100): string {
    return `${keyParams}は${numberOfChars}文字以下で入力してください。`;
  }

  public static moreSelectedItems(count: number): string {
    return `+${count}件選択`;
  }

  public static moreItems(count: number): string {
    return `+${count}件`;
  }

  // Using in multiple search keyword
  // E.g: 設定名の絞り込み
  public static filterConditionTitle(itemLabel: string): string {
    return `${itemLabel}の絞り込み`;
  }

  public static overLimit(keyParams: string): string {
    return `作成できる${keyParams}の上限を超えています。`;
  }

  public static duplicated(keyParams: string): string {
    return `すでに存在する${keyParams}です。`;
  }

  /** At evaluation modal, show required quiz error list  */
  public static toggleRequiredQuizCount(keyParams: number): string {
    return `未回答（必須 <strong>*</strong>）: ${keyParams} 問`;
  }

  public static displaySummaryType(summary_type: string | number): string {
    const summaryTypeValue =
      summary_type == SummaryType.TOTAL
        ? MessageUtils.TemplateFrame.SummaryType.Total
        : MessageUtils.TemplateFrame.SummaryType.Average;

    return `集計方法：<strong> ${summaryTypeValue}</strong>`;
  }

  public static getSummaryTypeLabel(summaryType: string | number): string {
    let summaryTypeLabel = this.Global.None;
    if (summaryType == SummaryType.TOTAL) {
      summaryTypeLabel = MessageUtils.TemplateFrame.SummaryType.Total;
    } else if (summaryType == SummaryType.AVG) {
      summaryTypeLabel = MessageUtils.TemplateFrame.SummaryType.Average;
    }
    return summaryTypeLabel;
  }

  public static getNodeTypeLabel(nodeType: string | number): string {
    let nodeTypeLabel = '';
    if (nodeType == NodeType.FRAME) {
      nodeTypeLabel = MessageUtils.TemplateFrame.DefaultFrame;
    } else if (nodeType == NodeType.CELL) {
      nodeTypeLabel = MessageUtils.TemplateCell.DefaultCell;
    }
    return nodeTypeLabel;
  }

  public static version(key: string): string {
    return `バージョン ${key}`;
  }

  /**
   * Return e.g `最大値：10人` or `最大値：20個` on multiple selections
   * @param limit
   * @param unit (default: '個')
   * @returns
   */
  public static limitDescription(limit: number, unit: string = '個') {
    return `最大値：${limit}${unit}`;
  }

  public static sendMailForEvaluationAgain(key: string): string {
    return `この評価結果は ${key} \n に対して作成されています。再評価依頼通知を送りますか？`;
  }

  public static readonly Global = {
    // action
    Logout: `ログアウト`,
    Delete: `削除`,
    Save: `保存`,
    Confirm: `確認`,
    Cancel: `キャンセル`,
    OK: `OK`,
    Create: `作成`,
    Add: `追加`,
    Edit: `編集`,
    Decision: `確定`,
    UnitAssignment: `評価ユニット割当`,
    UnAssignUnitTemplate: `割当を解除`,
    Back: `戻る`,
    ResetPwd: `パスワードリセット`,
    ForgotPwd: `パスワードを忘れた場合はこちら`,
    Update: `更新`,
    Send: `送信`,
    CloseUp: `閉じる`,
    ShowAll: `すべて表示`,
    DoNotSend: `送信しない`,
    Setting: `設定`,
    ImportCSV: 'CSVインポート',
    ImportCSVError: 'メンバーを追加できない場合があります。',
    MessageDownloadErrorCSV: 'エラーログのダウンロード',
    ImportCSVNoHeaderError: 'CSVファイルのヘッダーを指定してください。',

    // roles
    Member: 'なし',
    GroupAdmin: 'グループ管理者',
    OrganizationAdmin: '組織管理者',
    ServiceAdmin: 'サービス管理者',

    // empty data
    EmptyOrNotFoundData: `データがありません。`,
    EmptyGroup: 'グループなし',
    EmptyUser: 'メンバーなし',

    // amount data
    GroupCount: 'グループ：',
    UserCount: 'メンバー：',
    TemplateAllocationCount: '評価ユニット:',

    // placeholder
    PleaseSelect: '選択してください',
    SearchGroup: `グループを検索`,
    SearchUser: 'example@example.com',
    SearchEvaluationTemplate: '評価ユニット名',
    Search: `検索`,

    // log
    CreatedAt: `作成日`,
    Created: `作成日時：`,
    Updated: `更新日時：`,
    UpdatedAt: `更新日`,

    // site menu
    SiteMenuItem: {
      THEME_MODE: 'ダークモード',
      DASHBOARD: '管理者用ダッシュボード',
      DASHBOARD_MEMBER: 'ダッシュボード',
      ORG_CHART: '組織',
      USER: 'メンバー',
      GROUP: 'グループ',
      MEMBER: 'メンバー',
      EVALUATION_UNIT: '評価ユニット',
      EVALUATION_TEMPLATE: 'テンプレート',
      EVALUATION_REGISTER: '評価登録',
      EVALUATION_RESULT: '評価結果',
      PRIVACY_POLICY: 'プライバシーポリシー',
      TERMS_OF_SERVICE: '利用規約',
      SUMMARY_SETTING: '集計',
      EVALUATION_EVENT_SCHEDULE: 'カレンダー',
      EVENT_EVALUATION: '評価イベント',
    },

    // section site menu
    SiteMenuSection: {
      MANAGEMENT: '管理',
    },

    // pagination
    Pagination: {
      ItemsPerPageLabel: `表示件数`,
    },

    // breadcrumb
    Breadcrumb: {
      DASHBOARD: '管理者用ダッシュボード',
      ORGANIZATION: '組織一覧',
      USER: 'メンバー一覧',
      GROUP: 'グループ一覧',
      EVALUATION_UNIT: '評価ユニット',
      EVALUATION_TEMPLATE: 'テンプレート一覧',
      EVALUATION_REGISTER: '評価ユニット一覧',
      EVALUATION_RESULT: '評価結果一覧',
      EVENT_EVALUATAION: '評価イベント',
    },

    // filter
    Filter: {
      Title: 'フィルタを追加',
      Email: 'メール',
      Button: '適用',
      FullName: '氏名',
    },

    // Jump Session
    JumpSession: {
      SelectFrameOrCell: `フレームまたはセルを選択`,
      PlaceholderSearchFrameCell: `評価ユニット直下のセル、フレームのみ選択できます。`,
    },

    // others
    ALL: 'すべて',
    UpdateSuccess: `更新しました。`,
    Deleted: '削除済',
    Pieces: '個',
    Person: '人',
    None: 'ー',
    NonAffiliation: '非所属',
    EvaluationNotPossible: '評価不可',
    CSVImportSuccess: 'メンバーが追加されました。',

    // confirm message
    ChangeOrgWarning: `新しい組織を変更すると、\n 関連情報がリセットされます。\n 変更してもよろしいですか？`,
    EmailValidation: `メールアドレスを正しく入力してください。`,
    ConfirmDeleteMessage: `この情報が削除されます。よろしいですか？`,
    ConfirmDeleteMessageWithRelevantData: `この情報が削除されます。よろしいですか？\n 集計、評価イベントも全て削除されます。`,
    ConfirmCancelMessage: `破棄されますが、よろしいですか？`,
    ConfirmResetPwdMessage: `本当にパスワードをリセットしますか？`,
    ConfirmChangeNodeMessage: '移動すると編集中のデータが保存されません。\n 本当に移動しますか？',
    ConfirmMovePageMessage: 'ページを移動しますか？入力の内容は保存されません。',
    ConfirmSelectedSearchResult: '編集内容が上書きされますが、よろしいですか？',
    ConfirmCreateTemplateFromUnit: `この評価ユニットをテンプレートとして保存しますか？`,
    ConfirmUseEvaluationTemplate: `既存の編集内容が上書きされますが、本当によろしいですか？`,

    SnackBar: {
      Error: `エラーが発生しました。`,
      Warning: `警告があります。`,
      Info: `新しい情報があります`,
      CreateSuccess: `作成しました。`,
      UpdateSuccess: `更新しました。`,
      ResetPwdSuccessfully: `パスワードをリセットしました。`,
      RegisterEvaluation: `評価登録しました。`,
    },

    // common error
    UserNotFoundException: '更新対象データが存在しません。',
    ImageUploadMaximumFileSize: `${imgMaxSizeUpload_MiB}MB以下のファイルを選択してください。`,
    ReachedLimitChangePassword: `パスワード変更リクエストの上限に達しました。\n 1時間後に再度お試しください。`,
    ImageUploadValid: '画像ファイルを選択してください。',
    CSVUploadMaximumFileSize: `${imgMaxSizeUpload_MiB}MB以下のファイルを選択してください。`,
    CSVUploadWrongFormat: `CSVの形式が正しくありません。`,
    CSVUploadLimitRecord: `${limitRecordCSV}行以下のファイルを選択してください。`,
  };

  public static readonly EventCalendar = {
    dayGridMonth: '月',
    timeGridWeek: '週',
    timeGridDay: '日',
    Today: '今日',
    CreateEvent: 'イベント追加',
    Group: 'グループ',
  };

  public static readonly Login = {
    PlaceholderEmail: 'Eメールを入力してください。',
    PlaceholderPwd: 'パスワードを入力してください。',
    Title: 'ログイン',
    Email: 'Eメール',
    Password: 'パスワード',
    RememberPassword: 'ログイン状態を保存',
    Error: {
      WrongFormatEmail: 'メールアドレスを正しく入力してください。',
      AccountWrongPassOrEmail: 'Eメール、またはパスワードが間違っています。',
      AccountNotFound: 'そのEメールアカウントが存在しません。',
    },
    RedirectingNotification: '今からリダイレクト中です。少々お待ちください。',
  };

  public static readonly Organization = {
    Title: '組織一覧',
    Name: '組織名',
  };

  public static readonly OrganizationDetail = {
    Placeholder: `組織名`,
  };

  public static readonly Group = {
    Name: `グループ名`,
    Button: `グループ作成`,
    ColumnHeader: `グループ一覧`,
  };

  public static readonly UserList = {
    ButtonRoleServiceOrg: MessageUtils.Global.Create,
    ButtonRoleGroup: MessageUtils.Global.Add,
    Columns: {
      Name: '氏名',
      Email: 'メールアドレス',
      Role: '管理者ロール',
      OrgName: '組織',
    },
  };

  public static readonly UserDetail = {
    LastName: `姓`,
    FirstName: `名`,
    Email: `メール`,
    Role: `管理者ロール`,
    OrgTitle: `組織`,
    GroupName: `所属グループ一覧`,
    AddGroupButton: `グループに追加`,

    RequiredOrg: `所属組織を選択してください。`,
    InvalidEmail: `無効なメールアドレスです。`,

    // ORG_ADMIN
    Groups: `グループ`,
    DescriptionGroups: `管理者ロールがグループ管理者なら、１つしか選択できません。`,
    RequiredGroups: `所属グループを選択してください。`,
    ErrorOnlyOneGroup: `1つ選択してください。`,
    ConfirmMsgRemoveUser: `このグループから外します。よろしいですか？`,

    ChangeRoleAlert: `ロールを変更すると、\n 関連情報がリセットされます。`,
  };

  // evaluation unit template list, input
  public static readonly EvaluationTemplate = {
    UnitName: `評価ユニット名`,
    Version: `バージョン`,
    UpdateDate: `更新日`,
    BackToUnit: '評価ユニット一覧へ',
    AllocatedUnitName: `割当済み評価ユニット名`,
    AllocatedToGroup: 'グループに割当',
    ConfirmMessageUnAssignFromGroup: '評価ユニットの割り当てを解除します。よろしいですか？',
    ConfirmDeleteTemplateAlreadyInUse: 'すでに利用されている評価ユニットです。本当に削除しますか？',

    // Template evaluation - SP20
    TemplateName: `テンプレート名`,
    BackToTemplate: `テンプレート一覧へ`,
  };

  // evaluation unit template - unit input / view part
  // include message, context menu..
  public static readonly TemplateUnit = {
    DefaultUnit: '評価ユニット',
    DefaultTemplate: '評価ユニットテンプレート',
    UnitName: `評価ユニット名`,
    FrameCreation: 'フレーム作成',
    CellCreation: 'セル作成',
    ConfirmCancelMessage: '変更内容が全て破棄されます。よろしいですか？',
    ConfirmChangeAnotherNode: '移動すると編集中のデータが保存されません。\n 本当に移動しますか？',
    PleaseSelectToEdit: '編集したい要素を選択してください。',

    // Template evaluation - SP20
    TemplateName: `テンプレート名`,
    CreateTemplate: `テンプレート作成`,
    Copy: `コピー`,
    SuffixCopy: 'のコピー',
    Preview: `プレビュー`,
    CellNameShow: `セル名表示`,
    CellNameHide: `セル名非表示`,
    Public: `公開`,
    Draft: `下書き`,
    SelectTemplate: 'テンプレート選択',
    InOrganization: '組織内',
    EvaluationTemplate: 'テンプレート',
  };

  // evaluation unit template - frame input / view part
  public static readonly TemplateFrame = {
    DefaultFrame: 'フレーム',
    FrameName: 'フレーム名',
    Summary: '集計',
    SummaryType: {
      Total: `合計`,
      Average: `平均`,
    },
    SearchGuidance: 'フレーム名で検索して複製することができます。',
    OverFrameLevelError: 'これより下にフレームを作成することができません。',
    RequiredCellError: '評価セルを追加する必要があります。',
  };

  // evaluation unit template - frame input / view part
  public static readonly TemplateCell = {
    DefaultCell: 'セル',
    CellName: 'セル名',
    Question: '質問テキスト',
    Answer: '評価パート',
    AnswerType: {
      TextArea: `記述式`,
      Checkbox: `チェックボックス`,
      Select: `プルダウン`,
      Radio: `ラジオ`,
    },
    DefaultOption: '選択肢',
    AddOption: '選択肢を追加',
    Point: 'ポイント',
    PointPlaceHolder: '例：99.99',
    Required: '必須',

    SearchGuidance: 'セル名で検索して複製することができます。',
    JumpingDest: 'スキップ遷移先',
    JumpingNoSetting: 'スキップなし',
  };

  public static readonly Error = {
    OneOrgMustBeOneOrgAdmin: '組織には組織管理者が一人以上必要です。',
    NoMoreSelections: 'これ以上選択できません。',
  };

  public static readonly EventEvaluation = {
    Title: '評価登録',
    Columns: {
      Evaluatee: '評価対象者',
      EvaluationTemplateName: `イベント名`,
      EventTime: `開始`,
      GroupName: `グループ`,
    },
    Action: {
      BulkRegister: '一括登録',
    },
  };

  public static readonly EvaluationRegister = {
    Title: '評価登録',
    Columns: {
      EvaluationTemplateName: `評価ユニット名`,
      NumberOfUses: `使用回数`,
      Version: `バージョン`,
      GroupName: `グループ`,
    },
    Action: {
      StartRegister: '評価開始',
      Register: '評価登録',
    },
  };

  public static readonly EvaluationProcess = {
    CompletedAnswers: '全て回答済み',
    PersonCount: '人',
    QuestionCount: '問',
    RequiredInputOrSelect: '回答を入力または選択してください。',
    TextareaPlaceholder: '回答の入力',
  };

  public static readonly GroupAssignUnit = {
    Version: `バージョン:`,
  };

  public static readonly EvaluationResult = {
    Table: {
      Columns: {
        Evaluator: '評価対象者',
        OrgName: '組織名',
        GroupName: 'グループ名',
        EvaluationUnitName: '評価ユニット名',
        EvaluatorName: '評価者',
        Event: 'イベント',
      },
    },
    Filter: {
      Evaluator: '評価対象者',
      EvaluationUnitName: '評価ユニット名',
      SelectOrganization: '組織を選択',
      SelectGroup: 'グループを選択',
      EvaluatorName: '評価者',
    },

    DetailScreen: {
      Question: `質問`,
      Answer: `回答`,
      NoAnswer: `未回答`,
      TotalPoint: `ポイント：`,
      OpenAll: `すべて開く`,
    },
  };

  public static readonly Date = {
    Separator: '〜',
    Start: '開始日',
    End: '終了日',
    Year: '年',
    Month: '月',

    Invalid: '期間を正しく入力してください。',
    Required: '期間を入力してください。',
  };

  public static readonly GroupDetailTab = {
    ASSIGNED_MEMBER: '所属メンバー',
    ALLOCATED_EVALUATION_UNIT: '評価ユニット',
  };

  public static readonly ErrorPage = {
    CommonTitle: 'エラー発生',
    CommonInaccessibleSubTitle: '申し訳ございませんが、アクセスしようとしたページは表示できませんでした。',

    BackToLogin: 'ログイン画面へ',
    BackToHome: 'ホームへ',

    InternalErrorMessge: this.Global.SnackBar.Error,

    PageNotFoundTitle: 'ページが見つかりません',
    PageNotFoundSubTitle: '申し訳ございませんが、アクセスしようとしたページが見つけませんでした。',

    SessionTimeOutTitle: 'セッションタイムアウト',
    SessionTimeOutSubTitle: 'セッションが切れています。もう一度ログインし直してください。',

    ItemNotFoundSubTitle: '申し訳ございませんが、アクセスしようとしたデータが見つかりませんでした。',
    ItemNotFoundEvent: `イベントが見つかりませんでした。`,
    ItemNotFoundTemplate: `テンプレートが見つかりませんでした。`,
  };

  public static readonly Summary = {
    Name: '名前',
    TypeNode: 'タイプ',
    Count: '評価回数',
    Type: '集計方法',
    Point: '平均 (pt)',
    Title: '集計結果',
    Max: '最大 (pt)',

    // menu
    IndividualResult: '個別結果評価',
    UnitDetail: '評価ユニット詳細',
  };

  public static readonly MaintenanceNotice = {
    SiteIsUnderMaintenance: `サイトメンテナンス中です。`,
    ThankYouAndPleaseWaitForMoment: `日頃はSTaXをご利用いただき誠にありがとうございます。 現在システムメンテナンス中です。 ご利用再開まで今しばらくお待ちください。`,
  };

  public static readonly PolicyAndTerms = {
    TermsHeading: '「STαX（スタックス）」サブスクリプション規約',
  };

  public static readonly ForgotPassword = {
    Title: `パスワードの再設定`,
    SendMailSuccess: `パスワードリセットリンクを送信しました。`,
    BackToLogin: `ログイン画面へ戻る`,
  };

  // Define common for which component uses input password
  public static readonly InputPassword = {
    NewPassword: `新しいパスワード`,
    ConfirmPassword: '確認パスワード',
    ConfirmPasswordNotMatch: 'パスワードが一致しません。',
    Guidance: `パスワードは最低一つの大文字、小文字、数字、記号が 含まれており、かつ8文字以上である必要があります。`,
    RegExpPasswordError: 'パスワードには最低一つの大文字、小文字、数字、記号が含まれている必要があります。',
    MinLength: 'パスワードは8文字以上で入力してください。',
  };

  public static readonly ResetPassword = {
    Title: `パスワードの更新`,
    PlaceholderNewPassword: `新しいパスワードを入力`,
    PlaceholderConfirmPassword: `確認パスワードを入力`,
    LinkOutOfDate: `指定期間が経過したため、<br>パスワードをリセットすることができません。`,
    LinkNavigateForgotPassword: `パスワードを忘れた場合はこちら`,
    ResetLinkExpiredError: `このリンクはすでに使用されています。パスワード\nを更新したい場合は再度リクエストしてください。`,
  };

  public static readonly ChangePassword = {
    Key: 'パスワード変更',
    CurrentPasswordNotMatch: 'パスワードが間違っています。',
    CurrentPassword: '現在のパスワード',
    Password: this.InputPassword.NewPassword,
    ConfirmPassword: this.InputPassword.ConfirmPassword,
  };

  public static readonly MyProfile = {
    Key: 'プロフィール',
  };

  public static readonly SummarySetting = {
    SummarySettingList: '集計一覧',
    Columns: {
      Group: 'グループ',
      SummaryName: '集計名',
      StartDate: '開始',
      EndDate: '終了',
      EvaluationUnit: '評価ユニット',
    },
    Create: {
      SummaryNameDefault: '集計',
      SummaryName: '集計名',
      SettingPeriod: '集計期間',
    },
    Detail: {
      Title: '集計詳細',
      NoUnitSelected: '評価ユニットが選択されていません。',
    },
  };

  public static readonly EvaluationStatus = {
    Unrated: `未評価`,
    Evaluated: `評価済`,
  };

  public static readonly EventScheduleDialogView = {
    Table: {
      Rate_Setting: `評価設定`,
    },
    ConfirmDelete: {
      Title: `定期的な予定の削除`,
    },
    Options: {
      ThisAppointment: `この予定`,
      AllFutureAppointments: `これ以降のすべての予定`,
      AllAppointments: `すべての予定`,
    },
    ConfirmEdit: {
      Title: `定期的な予定の編集`,
    },
  };

  public static readonly ScheduleEventDialog = {
    Name: 'イベント名',
    Group: 'グループ',
    Date: '日付',
    User: '評価対象者',
    Note: 'メモ',
    ChangeGroup: '評価設定がリセットされますが、本当にグループを変更しますか？',
    ChangeUser: `評価対象者を未選択にすると、評価設定も削除されますがよろしいですか？`,
    AddBtn: '評価ユニットを追加',
    Unit: '評価ユニット',
    Evaluator: '評価者',
    RepeatDialog: {
      Title: '定期的な予定の編集',
      ThisEvent: 'この予定',
      FromThisEvent: 'これ以降のすべての予定',
    },
  };

  public static readonly EventCalendarSendMail = {
    Create: '評価者にイベント内容を送信しますか？',
    Edit: '評価者に変更内容を送信しますか？',
    Delete: '評価者にキャンセル内容を送信しますか？',
  };

  public static readonly ScheduleRepeat = {
    DoNot: '繰り返さない',
    EveryDay: '毎日',
    EverySun: '毎週 日曜日',
    EveryMon: '毎週 月曜日',
    EveryTue: '毎週 火曜日',
    EveryWed: '毎週 水曜日',
    EveryThu: '毎週 木曜日',
    EveryFri: '毎週 金曜日',
    EverySat: '毎週 土曜日',
    EveryWeekday: '毎週平日',
  };
}
