import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, UrlSegment } from '@angular/router';
import { Observable, of, tap } from 'rxjs';
import { HttpStatus } from '../enums/http-status.enum';
import { AccountType } from '../interfaces/user.interface';
import { RedirectService } from '../services/redirect/redirect.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(private readonly userService: UserService, private readonly redirectService: RedirectService) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.hasPermission(next.data['permission']);
  }

  canActivateChild(next: ActivatedRouteSnapshot): Observable<boolean> {
    const permissionData = next.data['permission'] || next.parent?.data?.['permission'];
    return this.hasPermission(permissionData);
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.hasPermission(route.data['permission'], segments);
  }

  private hasPermission(permissionData?: [AccountType], segments: UrlSegment[] = []): Observable<boolean> {
    if (permissionData) {
      return this.userService.hasPermission$(permissionData).pipe(
        tap(hasPermission => {
          if (!hasPermission) {
            // TODO: ?????? why check permission then check forbidden access ?????
            this.redirectService.redirectToPageError(HttpStatus.ForbiddenAccess);
          }
        })
      );
    }
    return of(true);
  }
}
