import { BaseError } from './base-error.interface';

export class SessionTimeoutError extends BaseError implements Error {
  constructor(message?: string) {
    super(true, true);

    this._name = 'SessionTimeoutError';

    if (message) {
      this._message = message;
    }
  }
}
