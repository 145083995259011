import { SidebarItem as SidebarItemEnum } from '../enums/sidebar.enum';
import { AccountType } from '../interfaces/user.interface';
import { MessageUtils } from '../utils/message.utils';
import { AppRoutingConstant } from './app-routing.constant';

export const RolesConstant = {
  MEMBER: {
    role_id: AccountType.MEMBER,
    description: MessageUtils.Global.Member,
  },
  GROUP_ADMIN: {
    role_id: AccountType.GROUP_ADMIN,
    description: MessageUtils.Global.GroupAdmin,
  },
  ORGANIZATION_ADMIN: {
    role_id: AccountType.ORGANIZATION_ADMIN,
    description: MessageUtils.Global.OrganizationAdmin,
  },
  SERVICE_ADMIN: {
    role_id: AccountType.SERVICE_ADMIN,
    description: MessageUtils.Global.ServiceAdmin,
  },
};

/* ----------------------------------------- */
/*          ADMIN ROLE's SITE MENU           */
/* ----------------------------------------- */
const SiteItems = {
  DASHBOARD: {
    label: MessageUtils.Global.SiteMenuItem.DASHBOARD,
    routerLink: '/',
    item: SidebarItemEnum.DASHBOARD,
    icon: `icon-dashboard_inactive`,
    iconActive: `icon-dashboard_active`,
    sectionId: 0,
  },
  ORG_CHART: {
    label: MessageUtils.Global.SiteMenuItem.ORG_CHART,
    routerLink: `/${AppRoutingConstant.ORG[0]}`,
    item: SidebarItemEnum.ORG_CHART,
    icon: `icon-data_inactive`,
    iconActive: `icon-data_active`,
    sectionId: 1,
  },
  GROUP: {
    label: MessageUtils.Global.SiteMenuItem.GROUP,
    routerLink: `/${AppRoutingConstant.GROUP[0]}`,
    item: SidebarItemEnum.GROUP,
    icon: `icon-people_inactive`,
    iconActive: `icon-people_active`,
    sectionId: 1,
  },
  USER: {
    label: MessageUtils.Global.SiteMenuItem.USER,
    routerLink: `/${AppRoutingConstant.USER[0]}`,
    item: SidebarItemEnum.USER,
    icon: `icon-user_inactive`,
    iconActive: `icon-user_active`,
    sectionId: 1,
  },
  MEMBER: {
    label: MessageUtils.Global.SiteMenuItem.MEMBER,
    routerLink: '/member',
    icon: `icon-user_inactive`,
    iconActive: `icon-user_active`,
    roles: [AccountType.GROUP_ADMIN],
    sectionId: 1,
  },
  EVALUATION_UNIT: {
    label: MessageUtils.Global.SiteMenuItem.EVALUATION_UNIT,
    routerLink: `/${AppRoutingConstant.EVALUATION_UNIT[0]}`,
    item: SidebarItemEnum.EVALUATION_UNIT,
    icon: `icon-unit_inactive`,
    iconActive: `icon-unit_active`,
    sectionId: 1,
  },
  EVALUATION_TEMPLATE: {
    label: MessageUtils.Global.SiteMenuItem.EVALUATION_TEMPLATE,
    routerLink: `/${AppRoutingConstant.EVALUATION_TEMPLATE[0]}`,
    item: SidebarItemEnum.EVALUATION_TEMPLATE,
    icon: `icon-template_inactive`,
    iconActive: `icon-template_active`,
    sectionId: 1,
  },
  EVALUATION_EVENT_SCHEDULE: {
    label: MessageUtils.Global.SiteMenuItem.EVALUATION_EVENT_SCHEDULE,
    routerLink: `/${AppRoutingConstant.EVALUATION_EVENT_SCHEDULE[0]}`,
    item: SidebarItemEnum.EVALUATION_EVENT_SCHEDULE,
    icon: `icon-event_calendar_inactive`,
    iconActive: `icon-event_calendar_active`,
    sectionId: 2,
  },
  SUMMARY_SETTING: {
    label: MessageUtils.Global.SiteMenuItem.SUMMARY_SETTING,
    routerLink: `/${AppRoutingConstant.SUMMARY_SETTING[0]}`,
    item: SidebarItemEnum.SUMMARY_SETTING,
    icon: `icon-summary_setting_inactive`,
    iconActive: `icon-summary_setting_active`,
    sectionId: 3,
  },
  EVALUATION_RESULT: {
    label: MessageUtils.Global.SiteMenuItem.EVALUATION_RESULT,
    routerLink: `/${AppRoutingConstant.EVALUATION_RESULT[0]}`,
    item: SidebarItemEnum.EVALUATION_RESULT,
    icon: `icon-evaluation_result_inactive`,
    iconActive: `icon-evaluation_result_active`,
    sectionId: 4,
  },
};

/* ----------------------------------------- */
/*          MEMBER ROLE's SITE MENU          */
/* ----------------------------------------- */
const SiteItems_Member = {
  DASHBOARD_MEMBER: {
    label: MessageUtils.Global.SiteMenuItem.DASHBOARD_MEMBER,
    routerLink: '/',
    item: SidebarItemEnum.DASHBOARD,
    icon: `icon-dashboard_inactive`,
    iconActive: `icon-dashboard_active`,
    sectionId: 0,
  },
  // TODO: will delete later
  // EVALUATION_REGISTER_MEMBER: {
  //   label: MessageUtils.Global.SiteMenuItem.EVALUATION_REGISTER,
  //   routerLink: `/${AppRoutingConstant.EVALUATION_REGISTER[0]}`,
  //   item: SidebarItemEnum.EVALUATION_REGISTER,
  //   icon: `icon-evaluation_registration_inactive`,
  //   iconActive: `icon-evaluation_registration_active`,
  //   sectionId: 0,
  // },
  EVENT_EVALUATION: {
    label: MessageUtils.Global.SiteMenuItem.EVENT_EVALUATION,
    routerLink: `/${AppRoutingConstant.EVENT_EVALUATION[0]}`,
    item: SidebarItemEnum.EVENT_EVALUATION,
    icon: `icon-evaluation_registration_inactive`,
    iconActive: `icon-evaluation_registration_active`,
    sectionId: 0,
  },
  EVALUATION_RESULT_MEMBER: {
    label: MessageUtils.Global.SiteMenuItem.EVALUATION_RESULT,
    routerLink: `/${AppRoutingConstant.EVALUATION_RESULT[0]}`,
    item: SidebarItemEnum.EVALUATION_RESULT,
    icon: `icon-evaluation_result_inactive`,
    iconActive: `icon-evaluation_result_active`,
    sectionId: 0,
  },
};

/* ----------------------------------------- */
/*          HELP SITE MENU                   */
/* ----------------------------------------- */
export const SiteItems_Help = {
  PRIVACY_POLICY: {
    label: MessageUtils.Global.SiteMenuItem.PRIVACY_POLICY,
    routerLink: `/${AppRoutingConstant.PRIVACY_POLICY[0]}`,
    item: SidebarItemEnum.PRIVACY_POLICY,
    icon: `icon-policy_inactive`,
    iconActive: `icon-policy_active`,
    sectionId: 1,
  },
  TERMS_OF_SERVICE: {
    label: MessageUtils.Global.SiteMenuItem.TERMS_OF_SERVICE,
    routerLink: `/${AppRoutingConstant.TERMS_OF_SERVICE[0]}`,
    item: SidebarItemEnum.TERMS_OF_SERVICE,
    icon: `icon-terms_inactive`,
    iconActive: `icon-terms_active`,
    sectionId: 1,
  },
};

export const SideMenuSection = {
  1: MessageUtils.Global.SiteMenuSection.MANAGEMENT,
};

export const SiteItemsByRole = {
  [AccountType.SERVICE_ADMIN]: {
    items: [
      SiteItems.DASHBOARD,
      SiteItems.ORG_CHART,
      SiteItems.USER,
      SiteItems.EVALUATION_TEMPLATE,
      SiteItems.EVALUATION_RESULT,
    ],
  },
  [AccountType.ORGANIZATION_ADMIN]: {
    items: [
      SiteItems.DASHBOARD,
      SiteItems.GROUP,
      SiteItems.EVALUATION_UNIT,
      SiteItems.EVALUATION_TEMPLATE,
      SiteItems.USER,
      SiteItems.EVALUATION_EVENT_SCHEDULE,
      SiteItems.SUMMARY_SETTING,
      SiteItems.EVALUATION_RESULT,
    ],
  },
  [AccountType.GROUP_ADMIN]: {
    items: [
      SiteItems.DASHBOARD,
      SiteItems.EVALUATION_UNIT,
      SiteItems.USER,
      SiteItems.EVALUATION_EVENT_SCHEDULE,
      SiteItems.SUMMARY_SETTING,
      SiteItems.EVALUATION_RESULT,
    ],
  },
  [AccountType.MEMBER]: {
    items: [
      SiteItems_Member.DASHBOARD_MEMBER,
      // TODO: will delete later
      // SiteItems_Member.EVALUATION_REGISTER_MEMBER,
      SiteItems_Member.EVENT_EVALUATION,
      SiteItems_Member.EVALUATION_RESULT_MEMBER,
    ],
  },
};
