import { ResponsiveLayoutService } from 'src/app/infrastructure/services/responsive-layout.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from 'src/app/infrastructure/components/base-component/base.component';
import { SessionStorageConstant } from '../../constants/session-storage.constant';
import { SidebarGroup, SidebarLogo } from '../../interfaces/sidebar.interface';
import { SessionStorageService } from '../../services/session-storage.service';
import { SidebarService } from '../../services/sidebar.service';
import { UserService } from '../../services/user.service';
import { RoleUtils } from '../../utils/role.utils';
import { MainService } from '../../services/main.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends BaseComponent {
  @Input() logo: SidebarLogo;
  @Input() sidebarGroups: SidebarGroup[];

  @Output() closeSidebar: EventEmitter<any> = new EventEmitter<any>();

  public isVisible = false;

  public activeItem: any;
  public roleName: string;
  public isMember: boolean;

  private roleId: number;
  private _isExpand = false;
  private _isExpandOnHover = false;

  constructor(
    private readonly sidebarService: SidebarService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly userService: UserService,
    private readonly responsiveLayoutService: ResponsiveLayoutService,
    private readonly mainService: MainService
  ) {
    super();
  }

  get isExpand(): boolean {
    return this._isExpand || this._isExpandOnHover;
  }

  get hideRoleName(): boolean {
    return this.mainService.isPrivacyTermsUrl();
  }

  public onToggleExpand(): void {
    this.sidebarService.expandSidebar(!this._isExpand);
  }

  protected override onInit() {
    this.initVisible();
    this.initExpand();
    this.initExpandOnHover();
    this.roleId =
      this.userService.getUserProfile()?.role_id ||
      Number(this.sessionStorageService.getItem(SessionStorageConstant.ROLE_ID));
    this.roleName = this.getRoleName();
    this.isMember = this.checkRoleMember();
  }

  private getRoleName() {
    return RoleUtils.getRoleDescriptionById(this.roleId);
  }

  private checkRoleMember() {
    return RoleUtils.isMember(this.roleId);
  }

  private initVisible(): void {
    this.subscribe(this.sidebarService.isSideBarVisibleObs(), isVisible => {
      this.isVisible = isVisible;
    });
  }

  private initExpand(): void {
    this.subscribe(this.responsiveLayoutService.isDesktopLandscape(), isDesktopLandscape => {
      // In-case: If sidebar is in collapsed: keep current status regardless of the width
      if (this._isExpand) {
        this.sidebarService.expandSidebar(isDesktopLandscape);
      }
    });

    this.subscribe(this.sidebarService.getExpandSidebarObs(), isExpand => {
      this._isExpand = isExpand;
    });
  }

  private initExpandOnHover(): void {
    this.subscribe(this.sidebarService.getExpandOnHoverSidebarObs(), isExpand => {
      this._isExpandOnHover = isExpand;
    });
  }
}
