import { BaseComponent } from 'src/app/infrastructure/components/base-component/base.component';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { MenuListItem } from '../models/dynamic-menu-list.interface';
import { ThemeService } from 'src/app/infrastructure/services/theme.service';
import { Theme } from 'src/app/infrastructure/enums/theme.enum';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-dynamic-menu-list[menuListItems]',
  templateUrl: './dynamic-menu-list.component.html',
  styleUrls: ['./dynamic-menu-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicMenuListComponent extends BaseComponent {
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  @Input() menuListItems: MenuListItem[] = [];
  @Input() customTriggerOpenIcon: string = 'icon-context_menu';

  @Output() openMenu: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeMenu: EventEmitter<void> = new EventEmitter<void>();

  public isDark = false;

  public hasOpenMenu = false;

  constructor(private readonly cd: ChangeDetectorRef, private readonly themeService: ThemeService) {
    super();
  }

  public menuOpened(): void {
    this.hasOpenMenu = true;
    this.openMenu.emit();
  }

  public menuClosed(): void {
    this.hasOpenMenu = false;
    this.closeMenu.emit();
  }

  public onClick(item: MenuListItem): void {
    if (!item.onClick) {
      return;
    }
    // Output value
    item.onClick(item);
  }

  protected override onInit(): void {
    this.detectTheme();
  }

  private detectTheme(): void {
    this.subscribe(this.themeService.getTheme(), (theme: Theme) => {
      this.isDark = theme === Theme.DARK;
      this.cd.detectChanges();
    });
  }
}
