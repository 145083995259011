export enum UnitMode {
  VIEW,
  EDIT,
  CREATE,
}

export enum NodeType {
  UNIT = 'UNIT',
  FRAME = 'FRAME',
  CELL = 'CELL',
}

export enum NodeLevel {
  ONE = 1,
  TWO = 2,
  THREE = 3,
}
