/**
 * TEXT_AREA = 1
 * CHECKBOX = 2
 * SELECT = 3
 * RADIO = 4
 */
export enum AnswerType {
  NONE,
  TEXT_AREA,
  CHECK_BOX,
  SELECT,
  RADIO,
}

/**
 * TOTAL: 1
 * AVG: 2
 */
export enum SummaryType {
  NONE,
  TOTAL,
  AVG,
}
