import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { catchError, EMPTY, Observable } from 'rxjs';
import { ApiErrorCode } from 'src/app/infrastructure/enums/api-response-code.enum';
import { HttpStatus } from 'src/app/infrastructure/enums/http-status.enum';
import { BaseApiResponse } from './interfaces/base-api-response.interface';
import { ItemNotFoundError } from './interfaces/item-not-found-error.interface';
import { MaintainError } from './interfaces/maintain-error.interface';
import { SessionTimeoutError } from './interfaces/session-timeout-error.interface';
import { SystemRuntimeError } from './interfaces/system-runtime-error.interface';

export abstract class BaseApiService {
  private baseURL = '';

  /**
   * Creates an instance of BaseApiService.
   * @param http The HTTP client
   * @param featureURL The prefix API endpoin URL of each feature
   */
  constructor(private readonly http: HttpClient, public featureURL?: BASE_FEATURE_URL) {
    // for general apis but except special api (authenticate)
    if (featureURL) {
      this.baseURL = this.baseURL.concat(PREFIX_API).concat(featureURL);
    }
  }

  /**
   * Implement base method get
   *
   * @param endpoint The end point
   * @param params The parameters
   * @param reqOpts The request options
   * @returns
   */
  public get<T>(
    endpoint: string,
    params?: any,
    reqOpts?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    },
    suppressError: boolean = true
  ): Observable<T> {
    return this.http
      .get<T>(this.baseURL.concat(endpoint).concat(this.makeUrl(params)), reqOpts || OPTIONS_SERVICE)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error, reqOpts?.headers, suppressError);
        })
      );
  }

  /**
   * Implement base method post
   *
   * @param endpoint The end point
   * @param body The body
   * @param reqOpts The request options
   * @returns
   */
  post<T>(
    endpoint: string,
    body: any,
    reqOpts?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.http.post<T>(this.baseURL.concat(endpoint), body, reqOpts || OPTIONS_SERVICE).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error, reqOpts?.headers);
      })
    );
  }

  /**
   * Implement base method put
   *
   * @param endpoint
   * @param body The body
   * @param reqOpts The request options
   * @returns
   */
  put<T>(
    endpoint: string,
    body: any,
    reqOpts?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.http.put<T>(this.baseURL.concat(endpoint), body, reqOpts || OPTIONS_SERVICE).pipe(
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error, reqOpts?.headers);
      })
    );
  }

  /**
   * Implement base method delete
   *
   * @param endpoint The end point
   * @param params The parameters
   * @param reqOpts The request options
   * @returns
   */
  delete<T>(
    endpoint: string,
    params?: any,
    reqOpts?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.http
      .delete<T>(this.baseURL.concat(endpoint).concat(this.makeUrl(params)), reqOpts || OPTIONS_SERVICE)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return this.handleError(error, reqOpts?.headers);
        })
      );
  }

  /**
   * Join array parameter into string url
   * @param params
   * @returns
   */
  private makeUrl(params: any): string {
    let paramUrl = '';
    if (params) {
      if (!(params.headers instanceof HttpHeaders)) {
        paramUrl = buildURL(paramUrl, params);
      }
    }

    return paramUrl;
  }

  /**
   * Error handling
   * @param error
   * @param suppressError If true, error with status = 401, 403, 500 will be processed. Default is true
   * @returns
   */
  private handleError(error: HttpErrorResponse, reqHeaders: HttpHeaders| {
    [header: string]: string | string[];
  }, suppressError: boolean = true): Observable<any> {
    let selfThrowError = false;

    if (reqHeaders && (reqHeaders as HttpHeaders).has(SELF_THROW_ERROR_NAME)) {
      selfThrowError = true;
    }
    
    // System error: Runtime error | Sesstion timeout error | Forbidden | ItemNotFound | Unknow error
    if (
      suppressError &&
      error &&
      (error.status === HttpStatus.NotAuthorized ||
        error.status >= HttpStatus.InternalError ||
        this.isUnknowError(error) || // ApiUrl not found
        (!selfThrowError && error.status === HttpStatus.Error && error.error.code === ApiErrorCode.ITEM_NOT_FOUND))
    ) {
      this.forwardErrorHandler(error);

      return EMPTY;
    } else {
      // Business logic error
      console.log(`at base-api.service, business error are thrown ${error}`);
      throw error.error as BaseApiResponse;
    }
  }

  /**
   * Error handling
   * @param error
   */
  private forwardErrorHandler(error: HttpErrorResponse): void {
    let errorHandler = window['staxErrorHandler'] as ErrorHandler;
    
    if (errorHandler) {
      if (error.status === HttpStatus.InternalError) {
        errorHandler.handleError(new SystemRuntimeError(error.message));
      } else if (error.status === HttpStatus.Error && error.error.code === ApiErrorCode.ITEM_NOT_FOUND) {
        errorHandler.handleError(new ItemNotFoundError(error.message));
      } else if (error.status > HttpStatus.InternalError ||
        this.isUnknowError(error)) {
        // Handle for maintain mode
        errorHandler.handleError(new MaintainError(error.message));
      }

      errorHandler.handleError(new SessionTimeoutError(error.message));
    }
  }
  
  private isUnknowError(error: HttpErrorResponse) {
    return error && (error.status == 0 || error.status == HttpStatus.PageNotFound) && !error.ok;
  }
}

const SELF_THROW_ERROR_NAME = 'STAX-SELF-THROW-ERR';
/**
 * OPTIONS SERVICE
 * TYPE JSON
 */
export const OPTIONS_SERVICE = { headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=utf-8' }) };
export const OPTIONS_DISABLE_LOADING = { headers: new HttpHeaders({ 'Disallow-Loading-Spinner': 'Yes' }) };
export const OPTIONS_SELF_THROW_ERR = { headers: new HttpHeaders({ [SELF_THROW_ERROR_NAME]: '1' })};

/**
 * Base URL for all module
 *
 * @enum {number}
 */
export enum BASE_FEATURE_URL {
  AUTH = '/auth',
  ORG = '/org',
  GROUP = '/grp',
  USER = '/users',
  UNIT = '/unit',
  TEMPLATE = '/template',
  EVALUATION = '/evaluation',
  USER_PROFILE = '/user-profile',
  EVENT = '/event',
  SUMMARY_SETTING = '/summary-setting',
}

//============== private function or variable ============

export const PREFIX_API = '/stax/api';

/**
 * convert null or undefine to blank
 * @param value
 */
function toEmpty(value: Object): string {
  if (value == null || value == undefined) {
    return '';
  }

  return value.toString();
}

/**
 * Build url with parameters
 * TODO: maybe will apply qs lib (https://www.npmjs.com/package/qs)
 * @param rootUrl
 * @param params
 */
function buildURL(rootUrl: string, params?: any): string {
  let paramUrl: string = '';
  if (params) {
    let paramsArr = [];
    for (let k in params) {
      if (params[k]) {
        paramsArr.push(
          paramUrl
            .concat(k)
            .concat('=')
            .concat(encodeURIComponent(toEmpty(params[k])))
        );
      }
    }
    paramUrl = '?'.concat(paramsArr.join('&'));
  }

  return rootUrl.concat(paramUrl);
}
