import { ActivatedRoute, ActivatedRouteSnapshot, ParamMap } from '@angular/router';

export function getDeepestChildSnapshot(snapshot: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
  let deepestChild: ActivatedRouteSnapshot = snapshot.firstChild;
  while (deepestChild?.firstChild != null) {
    deepestChild = deepestChild.firstChild;
  }
  return deepestChild || snapshot;
}

export function paramMapToString(params: ParamMap, sep: string = '\n'): string {
  if (!params) return '';
  const sortedKeys = params.keys.sort();
  return sortedKeys
    .map(key => {
      let val = params.getAll(key);
      if (!val || !val.length) return '';
      return `${key}=${val}`;
    })
    .join(sep);
}

export const routeFullUrl = (route: ActivatedRoute) => rsnapshotFullUrl(route.snapshot);
export const rsnapshotFullUrl = (routesnap: ActivatedRouteSnapshot) =>
  '/' +
  routesnap.pathFromRoot
    .map(parts => parts.url.map(segment => segment.toString()).join('/'))
    .filter(url => url)
    .join('/');

export function isSamePathFromRoot(pathFromRoot: ActivatedRouteSnapshot, destPath: string): boolean {
  return pathFromRoot?.routeConfig.path === destPath;
}
