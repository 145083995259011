<section class="main-layout__section">
  <app-header (logout)="onLogout()"> </app-header>

  <div
    class="layout-page"
    [class.page-with-contextual-sidebar]="showSidebar"
    [class.page-with-contextual-expanded-sidebar]="showSidebar && isExpandSidebar">
    <app-sidebar [logo]="sidebarLogo" [sidebarGroups]="sidebarGroups">
      <div class="menu--content" content>
        <li class="sidebar__item">
          <a>
            <i [class]="isLight ? 'icon-dark_mode_inactive' : 'icon-dark_mode_active'"></i>
            <span class="label space__actions">{{ MessageUtils.Global.SiteMenuItem.THEME_MODE }}</span>
            <mat-slide-toggle color="accent" [checked]="!isLight" (change)="onChangeTheme($event)"></mat-slide-toggle>
          </a>
        </li>

        <!-- <li class="sidebar__item" *ngIf="showLogoutButton">
          <a href="javascript:void(0)" (click)="onLogout()">
            <i class="icon-logout"></i>
            <span class="label">{{ MessageUtils.Global.Logout }}</span>
          </a>
        </li> -->
      </div>
    </app-sidebar>
    <div class="content__wrapper" [class.--compact]="!isExpandSidebar">
      <router-outlet></router-outlet>
    </div>
  </div>
</section>
