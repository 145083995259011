import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { BaseComponent } from 'src/app/infrastructure/components/base-component/base.component';
import { Theme } from 'src/app/infrastructure/enums/theme.enum';
import { ThemeService } from 'src/app/infrastructure/services/theme.service';
import { SnackbarModel } from '../interfaces/snackbar.interface';
import { SnackBarService } from '../services/snackbar.service';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarComponent extends BaseComponent {
  public snackbar: SnackbarModel = {};
  public isDark = false;

  constructor(
    private snackBarRef: MatSnackBarRef<SnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) private data: any,
    private readonly snackbarService: SnackBarService,
    private readonly themeService: ThemeService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  public close() {
    this.snackBarRef.dismiss();
  }

  public handleThemeChange() {
    this.subscribe(this.themeService.getTheme(), (theme: Theme) => {
      this.isDark = theme === Theme.DARK;
      this.cd.detectChanges();
    });
  }

  protected override onInit(): void {
    this.snackbar = this.snackbarService.getSnackBarInfo(this.data.id);

    if (this.data.message) {
      this.snackbar.message = this.data.message;
    }

    this.handleThemeChange();
  }
}
