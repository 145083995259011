import { UserService } from 'src/app/infrastructure/services/user.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, RouterStateSnapshot } from '@angular/router';
import { Observable, of, map, from, take, mergeMap } from 'rxjs';
import { AuthService } from '../../auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard implements CanActivate, CanActivateChild, CanLoad {
  private readonly ERROR_URL = '/error';

  constructor(private readonly authService: AuthService, private readonly userService: UserService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (state && state?.url?.indexOf(this.ERROR_URL) > -1) {
      return of(true);
    }
    return this.isAuthenticated();
  }

  canActivateChild(): Observable<boolean> {
    return this.isAuthenticated();
  }

  canLoad(): Observable<boolean> {
    return this.isAuthenticated();
  }

  private isAuthenticated(): Observable<boolean> {
    return from(this.authService.isLoggedIn()).pipe(
      mergeMap((res: boolean) => {
        if (!res) {
          return of(false);
        }

        return this.userService.getMyProfile().pipe(map(() => true));
      }),
      take(1)
    );
  }
}
