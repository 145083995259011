import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutingConstant } from '../constants/app-routing.constant';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private localStorage!: Storage;
  private isEnabled = false;
  private pageSyncReload = [AppRoutingConstant.LOGIN[0], AppRoutingConstant.FORGOT_PASSWORD[0]];

  constructor(@Inject(PLATFORM_ID) private readonly platformId: Object, private router: Router) {
    if (!window.localStorage) {
      this.isEnabled = false;
      console.error('Current browser does not support Local Storage');
      return;
    }
    this.isEnabled = true;
    this.localStorage = window.localStorage;
    this.listenLocalStorageChange();
  }

  public setItem(key: string, value: string): void {
    if (isPlatformBrowser(this.platformId) && this.isEnabled) {
      this.localStorage.setItem(key, value);
    }
  }

  public getItem(key: string): string | null {
    if (!isPlatformBrowser(this.platformId) && !this.isEnabled) {
      return null;
    }

    return this.localStorage.getItem(key) || null;
  }

  public removeItem(key: string): void {
    if (isPlatformBrowser(this.platformId) && this.isEnabled) {
      this.localStorage.removeItem(key);
    }
  }

  public clear(): void {
    this.localStorage.clear();
  }

  public setObject(key: string, value: unknown): void {
    if (!isPlatformBrowser(this.platformId) && !this.isEnabled) {
      return;
    }
    this.localStorage.setItem(key, JSON.stringify(value));
  }

  public getObject<TType = unknown>(key: string): TType | null {
    if (!isPlatformBrowser(this.platformId) && !this.isEnabled) {
      return null;
    }
    return JSON.parse(this.localStorage.getItem(key) || null) as TType;
  }

  private listenLocalStorageChange() {
    window.addEventListener('storage', () => {
      if (this.localStorage.getItem('isSyncLogin')) {
        // Set is sync login to false when listen event
        this.localStorage.setItem('isSyncLogin', 'false');

        // Navigate dashboard if tab in page forgot password and login when reset password success
        if (this.pageSyncReload.includes(window.location.pathname.replace('/', ''))) {
          this.router.navigate(AppRoutingConstant.DASHBOARD);
        }
      }
    });
  }
}
