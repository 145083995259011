export enum HttpException {
  InternalErrorException = 'InternalErrorException',
  InvalidLambdaResponseException = 'InvalidLambdaResponseException',
  InvalidParameterException = 'InvalidParameterException',
  InvalidSmsRoleAccessPolicyException = 'InvalidSmsRoleAccessPolicyException',
  InvalidSmsRoleTrustRelationshipException = 'InvalidSmsRoleTrustRelationshipException',
  InvalidUserPoolConfigurationException = 'InvalidUserPoolConfigurationException',
  MFAMethodNotFoundException = 'MFAMethodNotFoundException',
  NotAuthorizedException = 'NotAuthorizedException',
  PasswordResetRequiredException = 'PasswordResetRequiredException',
  ResourceNotFoundException = 'ResourceNotFoundException',
  TooManyRequestsException = 'TooManyRequestsException',
  UnexpectedLambdaException = 'UnexpectedLambdaException',
  UserLambdaValidationException = 'UserLambdaValidationException',
  UserNotConfirmedException = 'UserNotConfirmedException',
  UserNotFoundException = 'UserNotFoundException',
}

export enum HttpStatus {
  InternalError = 500,
  Error = 400,
  NotAuthorized = 401,
  PageNotFound = 404,
  ForbiddenAccess = 403,
}
