import { BaseComponent } from 'src/app/infrastructure/components/base-component/base.component';
import { Component } from '@angular/core';
import { ThemeService } from './infrastructure/services/theme.service';
import { AnalyticsService } from './infrastructure/services/analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends BaseComponent {
  constructor(private readonly themeService: ThemeService, private readonly analyticsService: AnalyticsService) {
    super();

    this.analyticsService.addGAScript();

    this.analyticsService.setUpAnalytics();
  }

  protected override onInit(): void {
    this.themeService.setFirstTheme();
  }
}
