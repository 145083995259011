import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { Router, NavigationEnd, ActivatedRouteSnapshot, Data } from '@angular/router';
import { BehaviorSubject, filter, Subject } from 'rxjs';
import { Subscribable } from 'src/app/infrastructure/components/base-component/subscribable';
import { Breadcrumb } from '../interfaces/breadcrumb.interface';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService extends Subscribable {
  private readonly _breadcrumbs$ = new BehaviorSubject<Breadcrumb[]>([]);
  private readonly _breadcrumbCustom$ = new BehaviorSubject<Breadcrumb>(null);
  private _clickedItem$ = new Subject<string>();

  constructor(private router: Router) {
    super();
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      const root = this.router.routerState.snapshot.root;
      const breadcrumbs: Breadcrumb[] = [];
      this.addBreadcrumb(root, [], breadcrumbs);

      this.setBreadcrumbs(breadcrumbs);
    });
    this.subscribe(this._breadcrumbCustom$.asObservable(), (breadcrumb: Breadcrumb) => {
      this.updateBreadcrumbs(breadcrumb);
    });
  }

  public setBreadcrumbCustom(breadcrumb: Breadcrumb): void {
    this._breadcrumbCustom$.next(breadcrumb);
  }

  public getBreadcrumbs(): Observable<Breadcrumb[]> {
    return this._breadcrumbs$.asObservable();
  }

  public setBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
    const uniqueBreadcrumbsList = this.getUniqueList(breadcrumbs);
    this._breadcrumbs$.next(uniqueBreadcrumbsList);
  }

  public setClickedItem(item: string): void {
    this._clickedItem$.next(item);
  }

  public getClickedItem(): Observable<string> {
    return this._clickedItem$.asObservable();
  }

  private updateBreadcrumbs(newBreadcrumb: Breadcrumb): void {
    const newBreadcrumbs = this._breadcrumbs$.value.map((item: Breadcrumb) => {
      if (item.label.trim() === newBreadcrumb.label.trim()) {
        item.url = newBreadcrumb.url;
      }
      return item;
    });
    this._breadcrumbs$.next(newBreadcrumbs);
  }

  private getUniqueList(breadcrumbs: Breadcrumb[]): Breadcrumb[] {
    const seen = new Set();
    const result = breadcrumbs.filter(el => {
      const duplicate = seen.has(el.label);
      seen.add(el.label);
      return !duplicate;
    });

    return result;
  }

  private addBreadcrumb(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbs: Breadcrumb[]): void {
    if (route) {
      const routeUrl = parentUrl.concat(route.url.map(url => url.path));

      if (route.data['breadcrumb']) {
        const label = this.getLabel(route.data);
        if (label) {
          const breadcrumb = {
            label: label,
            url: this.getCustomUrl(route.data)
              ? this.getCustomUrl(route.data)
              : route.data['url'] || '/' + routeUrl.join('/'),
          };
          breadcrumbs.push(breadcrumb);
        }
      }

      this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
    }
  }

  private getLabel(data: Data): string {
    return typeof data['breadcrumb'] === 'function' ? data['breadcrumb'](data) : data['breadcrumb'];
  }

  private getCustomUrl(data: Data): string {
    return typeof data['urlCustom'] === 'function' ? data['urlCustom'](data) : null;
  }
}
