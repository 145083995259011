import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ResetPasswordRequest,
  ResetPasswordResponse,
} from 'src/app/pages/reset-password/model/reset-password.interface';
import { LoginRequest, LoginResponse } from '../../app/infrastructure/interfaces/login.interface';
import { BaseApiService, OPTIONS_DISABLE_LOADING, PREFIX_API } from '../base-api.service';
import { BaseApiResponse } from '../interfaces/base-api-response.interface';
import { AuthToken } from '../interfaces/token.interface';
import { ApiSuffixConstant } from './../constants/api-suffix.constant';

@Injectable({
  providedIn: 'root',
})
export class ApiAuthService extends BaseApiService {
  constructor(http: HttpClient) {
    super(http);
  }

  public login(input: LoginRequest): Observable<LoginResponse> {
    return this.post(`/auth/login`, input, { withCredentials: true });
  }

  public refreshToken(): Observable<AuthToken> {
    return this.post(`/auth/${ApiSuffixConstant.REFRESH_TOKEN}`, null, {
      withCredentials: true,
      ...OPTIONS_DISABLE_LOADING,
    });
  }

  public logout(): Observable<Object> {
    return this.post(`${PREFIX_API}/auth/logout`, null);
  }

  public forceLogout(): Observable<Object> {
    return this.post(`/auth/${ApiSuffixConstant.FORCE_LOGOUT}`, null);
  }

  public ping(showLoading: boolean = false): Observable<Object> {
    return this.post(`${PREFIX_API}/auth/ping`, null, showLoading ? null : OPTIONS_DISABLE_LOADING);
  }

  public forgotPassword(email: string): Observable<BaseApiResponse> {
    return this.post(`/auth/${ApiSuffixConstant.FORGOT_PASSWORD}`, { email });
  }

  public submitPassword(input: ResetPasswordRequest): Observable<ResetPasswordResponse> {
    return this.post(`/auth/${ApiSuffixConstant.SUBMIT_PASSWORD}`, input);
  }

  public verifyLinkForgotPassword(password_reset_token: string, code: string): Observable<BaseApiResponse> {
    return this.post(`/auth/${ApiSuffixConstant.VERIFIED_FORGOT_PASSWORD}`, { password_reset_token, code });
  }
}
