import { NgModule } from '@angular/core';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import { NewLottieComponent } from './components/new-lottie.component';

// load on demand using dynamic import exclude CanvasRenderer and HybridRenderer
// https://www.npmjs.com/package/ngx-lottie
export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg');
}

@NgModule({
  imports: [LottieModule.forRoot({ player: playerFactory }), LottieCacheModule.forRoot()],
  declarations: [NewLottieComponent],
  exports: [NewLottieComponent],
})
export class NewLottieModule {}
