export class BaseError {
    systemRuntime: boolean = true;
    sessionTimeout: boolean;
    itemNotFound: boolean;
    maintenanTime: boolean;

    protected _name: string;
    protected _message: string;

    constructor(systemRuntime: boolean, sessionTimeout: boolean = false, itemNotFound: boolean = false, maintenanTime: boolean = false) {
        this.systemRuntime = systemRuntime;
        this.sessionTimeout = sessionTimeout;
        this.itemNotFound = itemNotFound;
        this.maintenanTime = maintenanTime;
    }

    /**
     * Get name
     */
    get name(): string {
        return this._name;
    }

    /**
     * Get message
     */
    get message(): string {
        return this._message;
    }
}
