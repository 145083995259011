import { Injectable } from '@angular/core';
import { SessionStorageConstant } from '../constants/session-storage.constant';
import { User } from '../interfaces/user.interface';
import { BackupFilterService } from './backup-filter.service';
import { SessionStorageService } from './session-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenStorageService {
  private _accessToken = '';
  private _user: User;

  constructor(private readonly backupFilterService: BackupFilterService,
    private readonly sessionStorageService: SessionStorageService) {}

  public saveToken(token: string): void {
    this._accessToken = token;
  }

  public getToken(): string {
    return this._accessToken;
  }

  public saveUser(user: User): void {
    this._user = user;
  }

  public getUser(): User {
    return this._user;
  }

  public clearCredentials(): void {
    this._accessToken = '';
    this._user = null;
    this.backupFilterService.setBackupFilterData(undefined);
    this.backupFilterService.setBackupFilterTabData(undefined);
    this.sessionStorageService.removeItem(SessionStorageConstant.AVATAR);
  }
}
