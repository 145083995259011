import { RolesConstant } from '../constants/role.constant';

export class RoleUtils {
  public static getRoleDescriptionById(search_role_id: number): string {
    let result = Object.entries(RolesConstant)
      .filter(([key, value]) => {
        return value.role_id === search_role_id;
      })
      .flatMap(([key, value]) => value.description)[0];

    return result ? result : RolesConstant.MEMBER.description;
  }

  public static getRoleKeyById(search_role_id: number): string {
    let result = Object.keys(RolesConstant).find(key => {
      return RolesConstant[key].role_id === search_role_id;
    });

    return result ? result : 'MEMBER';
  }

  public static hasRole(src_role_id: number, target_role: any): boolean {
    return src_role_id === target_role?.role_id;
  }

  public static isMember(search_role_id: number) {
    return search_role_id === RolesConstant.MEMBER.role_id;
  }

  public static isServiceAdmin(search_role_id: number) {
    return search_role_id === RolesConstant.SERVICE_ADMIN.role_id;
  }

  public static isOrgAdmin(search_role_id: number) {
    return search_role_id === RolesConstant.ORGANIZATION_ADMIN.role_id;
  }

  public static isGroupAdmin(search_role_id: number) {
    return search_role_id === RolesConstant.GROUP_ADMIN.role_id;
  }
}
