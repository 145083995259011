import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { CommonConstant } from 'src/app/infrastructure/constants/common.constant';
import { SnackBarComponent } from '../components/snackbar.component';
import { ToastConstant } from '../constant/snackbar-display.constant';
import { SnackbarData } from '../interfaces/snackbar.interface';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService extends Subscription {
  constructor(private readonly snackBar: MatSnackBar) {
    super();
  }

  public getSnackBarInfo(id: string) {
    return ToastConstant.find(x => x.id === id);
  }

  public openSnackBar(data: SnackbarData, config?: MatSnackBarConfig<SnackbarData>) {
    this.snackBar.openFromComponent(SnackBarComponent, {
      data: data,
      ...CommonConstant.DEFAULT_SNACKBAR_CONFIG,
      ...config,
    });
  }
}
