import { MatSnackBarConfig } from '@angular/material/snack-bar';

export class CommonConstant {
  // Language
  public static readonly LANGUAGE_DEFAULT = {
    SHORT: 'ja',
    LONG: 'ja-JP',
  };

  // Paging
  public static readonly PAGING_PAGE_SIZE = 10;
  public static readonly PAGING_PAGE_INDEX = 1;
  public static readonly PAGING_OPTIONS = [10, 20, 30];

  //Table
  public static readonly TABLE_SIZE_FIRST_LOAD = 30;
  public static readonly TABLE_SIZE = 10;
  public static readonly DEBOUND_DELAY = 100;
  public static readonly START_PAGE = 1;
  public static readonly PAGE_LOAD_SECOND = 4;

  // Input
  public static readonly INPUT_DEBOUNCE_INTERVAL = 300;
  public static readonly INPUT_DEBOUNCE_THRESH_HOLD = 1;
  public static readonly DEFAULT_MAX_LENGTH = 100;
  public static readonly INPUT_TAG = 'INPUT';

  // Order by
  public static readonly ORDER_BY_ASC: string = 'asc';
  public static readonly ORDER_BY_DESC: string = 'desc';
  public static readonly SORT_BY = {
    CREATED_AT: 'created_at',
    UPDATED_AT: 'updated_at',
    NAME: 'name',
    ORG_NAME: 'org_name',
    EMAIL: 'email',
    START_DATE: 'start_date',
  };

  // auth
  public static readonly TIMER_REDIRECT_LOGIN = 10;
  public static readonly MILLI_SECOND = 1000;
  public static readonly SESSION_TIME_OUT = 'SessionTimeOut';
  public static readonly ITEM_NOT_FOUND = 'ItemNotFound';

  // Unit frame cell
  public static readonly MAX_FRAME = 100;
  public static readonly MAX_CELL = 100;

  // Snackbar config
  public static readonly DEFAULT_SNACKBAR_CONFIG_DURATION = 5 * 1000;
  public static readonly DEFAULT_SNACKBAR_CONFIG: MatSnackBarConfig = {
    duration: CommonConstant.DEFAULT_SNACKBAR_CONFIG_DURATION,
    horizontalPosition: 'center',
    verticalPosition: 'top',
    politeness: 'assertive',
  };

  public static readonly STATUS_ERROR = 'error';
  public static readonly STATUS_WARNING = 'warning';
  public static readonly STATUS_INFO = 'info';
  public static readonly STATUS_SUCCESS = 'success';

  // Point
  public static readonly DEFAULT_POINT_VALUE = '0';
  public static readonly PATTERN_POINT = '[0-9].';

  /**
   * https://www.cognitoforms.com/signup?source=signup
   * https://www.cognitoforms.com/support/32/building-forms/form-field-reference/email-field
   * The email address will require a ‘@’ and ‘.’ from the user before the form can be submitted.
   */
  public static readonly EMAIL_PATTERN = `[a-zA-Z0-9.-_+]{1,}@[a-zA-Z.-]{1,}[.]{1}[a-zA-Z]{2,}`;

  /**
   * Contains at least 1 number (?=.*?[0-9])
   * Contains at least 1 special character (?=.*?[$*.?-@#%&,><:;|_~+=\\^\\[\\]\\{\\}\\(\\)\\"\\!\\/\\\\\\'\\\`])
   * Contains at least 1 uppercase letter (?=.*?[A-Z])
   * Contains at least 1 lowercase letter (?=.*?[a-z])
   * From https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-policies.html?icmpid=docs_cognito_console_help_panel
   */
  public static readonly PASSWORD_PATTERN =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]\{\}\(\)?\-\“!@#%&\/\\,><\’:;|_~`+=])\S{8,256}$/;
  public static readonly PASSWORD_MINLENGTH = 8;

  // common input field key
  public static readonly FIELD_KEY = {
    COLOR_STATUS: 'color',
    // common for all organization, group, user on each page.
    NAME: 'name',
    SEARCH: 'search',
    KEYWORD: 'keyword',
    ROLE: 'role',
    ACTION: 'action',

    // define specific key base on particular purposes
    EMAIL: 'email',
    ORG_NAME: 'org_name',
    GROUP_NAME: 'grp_name',
    ORG_ID: 'org_id',
    GROUP_ID: 'grp_id',
    START_DATE_TIME: 'start_date_time',
    CREATED_AT: 'created_at',
    UPDATED_AT: 'updated_at',
    VERSION: 'version',
    USAGE_COUNT: 'usage_count',
    UNIT_NAME: 'unit_name',
    SELECT_ORGANIZATION: 'select_organization',
    SELECT_GROUPS: 'select_groups',
    EVALUATOR_NAME: 'evaluator_name',
    EVALUATEE_NAME: 'evaluatee_name',
    PASSWORD: 'password',
    CONFIRM_PASSWORD: 'confirm_password',
    EVALUATED: 'evaluated',
    START_DATE: 'start_date',
    END_DATE: 'end_date',
    UNIT_NAMES: 'unit_names',
    EVENT_NAME: 'event_name',
  };

  public static readonly INPUT_VALIDATOR_TYPE = {
    REQUIRED: 'required',
    DUPLICATE: 'duplicate',
    MAXLENGTH: 'maxlength',
    MINLENGTH: 'minlength',
    PATTERN: 'pattern',
    INVALID: 'invalid',
  };

  public static readonly TABLE_TYPE = {
    PAGINATION: 'pagination',
    INFINITE_SCROLL: 'infinite_scroll',
  };

  // DATE
  public static readonly DATE = {
    MIN: new Date('1970/01/01'),
    MAX: new Date('9999/12/31'),
    FORMAT: 'YYYY/MM/DD',
    FORMAT_TIME: 'HH:mm',
    FORMAT_DATE: 'YYYY-MM-DD',
    /** YYYY-MM-DDTHH:mm:ss.SSSZ */
    ISO8601: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
  };

  // DATE TIME
  public static readonly DATE_TIME = {
    DATE_TIME_EEE_JA_JP: 'llll',
  };

  // Avatar size
  public static readonly AVATAR_STATE_IN_DRAWER = {
    width: '2.5rem',
    height: '2.5rem',
  };

  // Static folder name
  public static readonly ASSETS_FOLDER_NAME = 'assets';
  public static readonly PRIVACY_FOLDER_NAME = 'privacy';
}
