import { UserService } from 'src/app/infrastructure/services/user.service';
import { Router } from '@angular/router';
import { MessageUtils } from 'src/app/infrastructure/utils/message.utils';
import { Component, EventEmitter, Output } from '@angular/core';
import { SidebarService } from '../../services/sidebar.service';
import { BaseComponent } from '../base-component/base.component';
import { MenuListItem } from 'src/app/modules/dynamic-menu-list/models/dynamic-menu-list.interface';
import { AppRoutingConstant } from '../../constants/app-routing.constant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent {
  @Output() logout = new EventEmitter<void>();

  public isExpandSidebar = true;
  public menuListItems: MenuListItem[];
  public isShowRightMenuSection = false;

  public readonly MessageUtils = MessageUtils;

  constructor(
    private readonly sidebarService: SidebarService,
    private readonly router: Router,
    private readonly userService: UserService
  ) {
    super();
  }

  public get avatarUrl(): string {
    return this.userService.avatarUrl;
  }

  public isServiceAdmin(): boolean {
    return this.userService.isServiceAdmin();
  }

  public gotoMyProfile(): void {
    this.router.navigate(AppRoutingConstant.MY_PROFILE);
  }

  public onLogout(): void {
    this.logout.emit();
  }

  protected override onInit(): void {
    this.subscribe(
      this.sidebarService.getExpandSidebarObs(),
      isExpandSidebar => (this.isExpandSidebar = isExpandSidebar)
    );

    this.initHelpMenuItem();
  }

  private initHelpMenuItem() {
    // Only show right menu item at breadcrum when user NOT in privacy policy, terms of service page
    this.isShowRightMenuSection = !this.isPrivacyTermsUrl();

    this.menuListItems = [
      {
        menuText: MessageUtils.Global.SiteMenuItem.PRIVACY_POLICY,
        menuIcon: 'icon-policy_header_menu',
        onClick: (_: MenuListItem) => {
          this.openUrl(`${AppRoutingConstant.PRIVACY_POLICY[0]}`);
        },
      },
      {
        menuText: MessageUtils.Global.SiteMenuItem.TERMS_OF_SERVICE,
        menuIcon: 'icon-terms_header_menu',
        onClick: (_: MenuListItem) => {
          this.openUrl(`${AppRoutingConstant.TERMS_OF_SERVICE[0]}`);
        },
      },
    ];
  }

  private isPrivacyTermsUrl(): boolean {
    return [`/${AppRoutingConstant.PRIVACY_POLICY[0]}`, `/${AppRoutingConstant.TERMS_OF_SERVICE[0]}`].includes(
      this.router.routerState.snapshot.url
    );
  }

  private openUrl(url: string) {
    window.open(url, '_blank');
  }
}
