import { MessageUtils } from 'src/app/infrastructure/utils/message.utils';
import { SnackBarItem } from '../enum/snackbar.enum';

export const ToastConstant = [
  {
    id: SnackBarItem.ERROR,
    class: 'error',
    icon: 'icon-toast_danger',
    message: MessageUtils.Global.SnackBar.Error,
  },
  {
    id: SnackBarItem.WARNING,
    class: 'warning',
    icon: 'icon-toast_warning',
    message: MessageUtils.Global.SnackBar.Warning,
  },
  {
    id: SnackBarItem.INFO,
    class: 'info',
    icon: 'icon-toast_info',
    message: MessageUtils.Global.SnackBar.Info,
  },
  {
    id: SnackBarItem.SUCCESS,
    class: 'success',
    icon: 'icon-toast_success',
    message: MessageUtils.Global.SnackBar.CreateSuccess,
  },
];
