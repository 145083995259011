import * as _moment from 'moment';
import { CommonConstant } from '../constants/common.constant';
const moment = _moment;

export class DateUtils {
  //private static readonly JST_OFFSET = 9;

  private static getUTCOffset(iso8601DateTime: string | Date): number {
    const localOffset = moment().utcOffset();
    const inputOffset = moment(iso8601DateTime).parseZone().utcOffset();

    return (localOffset === inputOffset ? inputOffset : localOffset) / 60;
  }

  /**
   * Convert string date time without `T` into `T` to be able to use pipe
   * Except Chrome doesn't understand about format string date without T.
   * So, it will be transformed correctly in pipe
   * [TODO] API should return ISO8601
   * @param strDate
   * @returns YYYY-MM-DD HH24:MI:SS -> YYYY-MM-DDTHH24:MI:SS
   */
  public static convertDateWithTimezone(strDate: string): string {
    if (strDate) {
      return strDate.replace(' ', 'T');
    }

    return '';
  }

  /**
   * Compare select date with today
   * @param select_date string | date should return ISO8601
   * @returns true if select date > today, false select date < today
   */
  public static greaterThanToday(select_date: string | Date): boolean {
    const today = new Date().getTime();
    const selectDate = new Date(select_date).getTime();

    return today < selectDate;
  }

  /**
   *
   * @param start_date_time should return ISO8601
   * @param end_date_time should return ISO8601
   * @returns true when start date = end date, false when start date != end date
   */
  public static isEquals(start_date_time: string | Date, end_date_time: string | Date): boolean {
    return this.duration(this.formatDate(start_date_time), this.formatDate(end_date_time)) === 0;
  }

  /**
   * Method check date when return same day or difference day
   * @param start_date_time string should return ISO8601
   * @param end_date_time string should return ISO8601
   * @returns if same day return format YYYY年MM月DD日(水) HH:mm - HH:mm
   * if differnce day return format YYYY年MM月DD日(水) HH:mm - YYYY年MM月DD日(木) HH:mm
   */
  public static formatDateTimeJP(start_date_time: string, end_date_time: string): string {
    if (DateUtils.isEquals(start_date_time, end_date_time)) {
      return this.formatDateTimeSameDay(start_date_time, end_date_time);
    }

    return this.formatDateTimeDifferenceDay(start_date_time, end_date_time);
  }

  /**
   * Format start with end date in a same day
   * @param start_date_time string should return ISO8601
   * @param end_date_time string should return ISO8601
   * @returns format YYYY年MM月DD日(水) HH:mm - HH:mm
   */
  public static formatDateTimeSameDay(start_date_time: string, end_date_time: string): string {
    const offset = this.getUTCOffset(start_date_time);
    return (
      moment(start_date_time)
        .utcOffset(offset)
        .locale(CommonConstant.LANGUAGE_DEFAULT.LONG)
        .format(CommonConstant.DATE_TIME.DATE_TIME_EEE_JA_JP) +
      ' - ' +
      moment(end_date_time).utcOffset(offset).format(CommonConstant.DATE.FORMAT_TIME)
    );
  }

  /**
   * Format start with end date in a difference day
   * @param start_date_time string should return ISO8601
   * @param end_date_time string should return ISO8601
   * @returns format YYYY年MM月DD日(水) HH:mm - YYYY年MM月DD日(木) HH:mm
   */
  public static formatDateTimeDifferenceDay(start_date_time: string, end_date_time: string): string {
    const offset = this.getUTCOffset(start_date_time);
    return (
      moment(start_date_time)
        .utcOffset(offset)
        .locale(CommonConstant.LANGUAGE_DEFAULT.LONG)
        .format(CommonConstant.DATE_TIME.DATE_TIME_EEE_JA_JP) +
      ' - ' +
      moment(end_date_time)
        .utcOffset(offset)
        .locale(CommonConstant.LANGUAGE_DEFAULT.LONG)
        .format(CommonConstant.DATE_TIME.DATE_TIME_EEE_JA_JP)
    );
  }

  /**
   * Format date time to GMT +9
   * @param date_time string | date should return ISO8601
   * @param type YYYY-MM-DD, HH:mm, YYYY-MM-DD HH:mm
   * @returns default YYYY-MM-DD
   */
  public static formatDate(date_time: string | Date, type?: string): string {
    const offset = this.getUTCOffset(date_time);
    return moment(date_time)
      .utcOffset(offset)
      .format(type || CommonConstant.DATE.FORMAT_DATE);
  }

  /**
   * Returns a date object as a string, using the ISO standard. (`YYYY-MM-DDTHH:mm:ss.SSSZ`)
   * @param date Date
   * @param format optional
   * @returns string
   */
  public static toISO8601(date: Date | string, format?: string): string {
    return moment(date).format(format || CommonConstant.DATE.ISO8601);
  }

  /**
   * Return a duration between start date time and end date time.
   * It will be used to render recurring events
   * @param start_date_time
   * @param end_date_time
   * @returns
   */
  public static duration(start_date_time: string | Date, end_date_time: string | Date): number {
    return new Date(end_date_time).getTime() - new Date(start_date_time).getTime();
  }

  /**
   * Return a date in new day status. It means that will return a date zero time
   *  E.g: 
   *   - 2022-12-15T07:00:00+07:00 (GMT+7)
   *   - 2022-12-15T09:00:00+09:00 (GMT+9)
   *   - 2022-12-15T00:00:00Z (UTC)
   * @param inputDate string | Date
   * @returns
   */
  public static toNewDate(inputDate: string | Date): Date {
    return new Date(this.formatDate(inputDate));
  }
}
