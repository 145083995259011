import { BaseError } from './base-error.interface';

export class ItemNotFoundError extends BaseError implements Error {
  constructor(message?: string) {
    super(true, false, true);

    this._name = 'ItemNotFoundError';

    if (message) {
      this._message = message;
    }
  }
}
