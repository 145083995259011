import { Component } from '@angular/core';
import { LoadingService } from '../../services/loading.service';
import { ThemeService } from '../../services/theme.service';
import { BaseComponent } from '../base-component/base.component';
import { Theme } from 'src/app/infrastructure/enums/theme.enum';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationConstant } from '../../constants/animation.constant';
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent extends BaseComponent {
  public isLoading = false;
  public isDark = false;
  public animationOptions: AnimationOptions;
  public styles: Partial<CSSStyleDeclaration> = AnimationConstant.LOADING_STYLES();

  constructor(private loadingService: LoadingService, private themeService: ThemeService) {
    super();
  }

  protected override onInit(): void {
    this.listenToLoading();
    this.listenToThemeChange();
  }

  private listenToLoading(): void {
    this.subscribe(this.loadingService.getLoadingObservable(), (state: boolean) => {
      this.isLoading = state;
    });
  }

  private listenToThemeChange(): void {
    this.subscribe(this.themeService.getTheme(), (theme: Theme) => {
      this.isDark = theme === Theme.DARK;
      this.animationOptions = AnimationConstant.LOADING(this.isDark);
    });
  }
}
