import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler, HttpInterceptor,
  HttpRequest,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { PREFIX_API } from 'src/api/base-api.service';
import { ApiAuthService } from 'src/api/services/api-auth.service';
import { environment } from 'src/environments/environment';
import { CommonConstant } from '../constants/common.constant';
import { HttpStatus } from '../enums/http-status.enum';
import { AuthTokenStorageService } from '../services/auth-token-storage.service';
import { DateUtils } from '../utils/date.utils';

const TOKEN_HEADER_KEY = 'Authorization';
const API_DOMAIN_URL = environment.apiUrl;
const UserTZ = 'STAX-GMT';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private _baseApiDomainURL: string = API_DOMAIN_URL;

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authTokenService: AuthTokenStorageService, private apiAuthService: ApiAuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<Object>> {
    let newURL = '';

    if (req.url.startsWith(`/${CommonConstant.ASSETS_FOLDER_NAME}/`)) {
      // Url to get static resources
      newURL = req.url;
    } else {
      // Make the api call
      newURL = req.url.startsWith(API_DOMAIN_URL) ? req.url : this._baseApiDomainURL.concat(req.url);
    }

    const newReq = req.clone({ url: newURL });

    // check api # login
    if (req.url.includes(`${PREFIX_API}/`)) {
      let authReq = req;
      const accessToken = this.authTokenService.getToken();

      authReq = this.addHeader(newReq, accessToken);

      return next.handle(authReq).pipe(
        catchError(error => {
          if (error instanceof HttpErrorResponse && error.status === HttpStatus.NotAuthorized) {
            return this.handle401Error(authReq, next);
          }

          return throwError(() => error);
        })
      );
    }

    return next.handle(newReq);
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.apiAuthService.refreshToken().pipe(
        switchMap(res => {
          this.authTokenService.saveToken(res.access_token);
          this.refreshTokenSubject.next(res.access_token);

          return next.handle(this.addHeader(request, res.access_token));
        }),
        finalize(() => {
          this.isRefreshing = false;
        })
      );
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap(token => next.handle(this.addHeader(request, token)))
    );
  }

  private addHeader(request: HttpRequest<any>, token: string) {
    const dateString = DateUtils.toISO8601(new Date());
    const timeZoneName = dateString.substring(dateString.length - 6) || '+09:00';

    return request.clone({
      headers: request.headers.set(TOKEN_HEADER_KEY, `Bearer ${token}`)
                              .set(UserTZ, timeZoneName),
    });
  }
}

export const AuthInterceptorProvider = [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }];
