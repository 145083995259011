import { AppRoutingConstant } from 'src/app/infrastructure/constants/app-routing.constant';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, UrlSegment } from '@angular/router';
import { getDeepestChildSnapshot } from '../../utils/get-deepest-child-snapshot';
import { HttpStatus } from '../../enums/http-status.enum';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  private _isRedirectToErrorPage$ = new BehaviorSubject<boolean>(false);

  private readonly LOGIN_ROUTER = 'login';

  constructor(private readonly router: Router, private route: ActivatedRoute) {}

  public redirectToLogin(initState?: boolean): void {
    const deepestSnapshot: ActivatedRouteSnapshot = getDeepestChildSnapshot(this.router.routerState.snapshot.root);

    if (deepestSnapshot.url.some((seg: UrlSegment) => seg.path.includes(this.LOGIN_ROUTER))) {
      return;
    }

    // TODO: waiting implement
    // const returnUrl =
    //   deepestSnapshot.url.map((url: UrlSegment) => url.path).join('/') || this.router.routerState.snapshot.url;
    // will not push in history so far when nagivate to login page
    // to prevent end-user do back url on browser
    this.router.navigate(AppRoutingConstant.LOGIN, { state: { init: initState }, replaceUrl: true });
  }

  public redirectToPageError(code: HttpStatus | string): void {
    // will not push in history so far when nagivate to error page
    // to prevent end-user do back url on browser
    this.setIsRedirectToErrorPage(true);

    this.router.navigate(AppRoutingConstant.ERROR, { state: { statusCode: code }, replaceUrl: true, relativeTo: this.route?.parent });
  }

  public setIsRedirectToErrorPage(hasError: boolean): void {
    this._isRedirectToErrorPage$.next(hasError);
  }

  public getIsRedirectToErrorPage(): Observable<boolean> {
    return this._isRedirectToErrorPage$.asObservable();
  }
}
