<section
  class="section-expansion-panel"
  [ngClass]="sideBarGroup.labelGroup ? 'sidebar-expand' : 'sidebar-normal'"
  [class.border-top]="index > 0"
  [class.expand]="!_isExpand"
  [class.theme-dark]="isDark">
  <ng-container *ngIf="sideBarGroup.labelGroup; then templateSidebarExpand; else templateSidebarNormal"></ng-container>
</section>

<ng-template #templateSidebarNormal>
  <li *ngFor="let item of sideBarGroup.items" class="sidebar__item" [class.active]="activeItem === item.item">
    <a [routerLink]="item.routerLink" (click)="onClick(item.item)">
      <i *ngIf="item?.icon" [class]="activeItem === item.item ? item?.iconActive : item?.icon"></i>
      <span class="label">{{ item.label }}</span>
    </a>
  </li>
</ng-template>

<ng-template #templateSidebarExpand>
  <mat-accordion>
    <mat-expansion-panel [expanded]="panelOpenState" [hideToggle]="true" (opened)="open()" (closed)="close()">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <i class="icon-arrow_down" [class.close]="!panelOpenState"></i>
          <div class="title">{{ sideBarGroup.labelGroup }}</div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ul>
        <ng-container *ngTemplateOutlet="templateSidebarNormal"></ng-container>
      </ul>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>
