import { Component } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/infrastructure/components/base-component/base.component';
import { AuthService } from '../../auth/services/auth.service';
import { SessionStorageConstant } from '../../constants/session-storage.constant';
import { SidebarGroup, SidebarLogo } from '../../interfaces/sidebar.interface';
import { MainService } from '../../services/main.service';
import { RedirectService } from '../../services/redirect/redirect.service';
import { SessionStorageService } from '../../services/session-storage.service';
import { SidebarService } from '../../services/sidebar.service';
import { ThemeService } from '../../services/theme.service';
import { MessageUtils } from '../../utils/message.utils';
import { Theme } from './../../enums/theme.enum';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent extends BaseComponent {
  public sidebarLogo: SidebarLogo;
  public sidebarGroups: SidebarGroup[];

  public showSidebar = true;
  public isExpandSidebar = true;

  public isLight = false;
  public readonly MessageUtils = MessageUtils;

  private logout$: Subscription;

  constructor(
    private readonly sidebarService: SidebarService,
    private readonly mainService: MainService,
    private readonly themeService: ThemeService,
    private readonly authService: AuthService,
    private readonly redirectService: RedirectService,
    private readonly sessionStorageService: SessionStorageService
  ) {
    super();
  }

  public onLogout(): void {
    this.logout$ = this.authService.logout().subscribe({
      complete: () => {
        this.sessionStorageService.removeItem(SessionStorageConstant.ROLE_ID);
        this.sessionStorageService.removeItem(SessionStorageConstant.REDIRECT_URL);
        this.sessionStorageService.removeItem(SessionStorageConstant.STATUS_CODE);
        this.redirectService.redirectToLogin(true);
      },
    });
  }

  public onChangeTheme(toggleChange: MatSlideToggleChange): void {
    const theme = toggleChange.checked ? Theme.DARK : Theme.LIGHT;
    this.isLight = !toggleChange.checked;
    this.themeService.setTheme(theme);
  }

  protected override onInit() {
    this.sidebarLogo = this.mainService.getSideBarLogo();
    this.sidebarGroups = this.mainService.getSidebarGroups();
    this.isLight = this.themeService.isLightTheme();
    this.initSideBar();
  }

  protected override onDestroy(): void {
    if (this.logout$) {
      this.logout$.unsubscribe();
    }
  }

  private initSideBar(): void {
    this.subscribe(this.sidebarService.isSideBarVisibleObs(), isVisible => (this.showSidebar = isVisible));

    this.subscribe(
      this.sidebarService.getExpandSidebarObs(),
      isExpandSidebar => (this.isExpandSidebar = isExpandSidebar)
    );
  }
}
