import { Location } from '@angular/common';
import { ErrorHandler, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseError } from 'src/api/interfaces/base-error.interface';
import { ApiAuthService } from 'src/api/services/api-auth.service';
import { SnackBarItem } from 'src/app/modules/snackbar/enum/snackbar.enum';
import { SnackBarService } from 'src/app/modules/snackbar/services/snackbar.service';
import { AppRoutingConstant } from '../constants/app-routing.constant';
import { CommonConstant } from '../constants/common.constant';
import { SessionStorageConstant } from '../constants/session-storage.constant';
import { AuthTokenStorageService } from '../services/auth-token-storage.service';
import { DialogService } from '../services/dialog.service';
import { RedirectService } from '../services/redirect/redirect.service';
import { SessionStorageService } from '../services/session-storage.service';

@Injectable({ providedIn: 'root' })
export class EngevaErrorHandler implements ErrorHandler {
  private lastCheck: number;

  constructor(
    private readonly authTokenStorageService: AuthTokenStorageService,
    private readonly router: Router,
    private readonly apiAuthService: ApiAuthService,
    private readonly redirectService: RedirectService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly location: Location,
    private readonly snackbarService: SnackBarService,
    private readonly dialogService: DialogService
  ) {
    window['staxErrorHandler'] = this;
  }

  /**
   * Handle error
   * @param error Error
   */
  handleError(error: any): void {
    let rootCause = (error.rejection || error) as BaseError;

    if (rootCause && rootCause.systemRuntime) {
      let current = Date.now();
      if (this.lastCheck && this.lastCheck + CHECK_DEBOUNCE_TIME > current) {
        return;
      }
      this.lastCheck = current;

      if (rootCause.sessionTimeout) {
        // Try to logout then navigate to session timeout page
        if (!EXCLUDE_FORCE_LOGOUT.includes(this.router.url)) {
          // in case:
          //    End-user has been forced to clear all cookie or no cookies on first state
          if (this.router.url === '/') {
            // in case:
            //    Save the current access URL into Session Storage that the user is not logged in and try to access it or the current session is terminated
            if (this.location.path().length > 1) {
              this.saveRedirectUrl(this.location.path());
            }

            if (this.sessionStorageService.getItem(SessionStorageConstant.ROLE_ID)) {
              // in case:
              //    Show session timeout page when user still staying on current page and force to clear all cookie then reload page
              this.redirectToErrorPage(CommonConstant.SESSION_TIME_OUT);
            } else {
              // in case:
              //    End-user goes to root page without cookie on first state (e.g: `<domain name>:<port>` or `<domain name>:<port>/`)
              this.redirectToLoginPage();
            }
          } else {
            if (STATIC_HELP_PAGE.includes(this.router.url)) {
              this.redirectToLoginPage();
            } else {
              if (this.location.path() === this.router.url) {
                this.saveRedirectUrl(this.router.url);
              }

              // force to logout to clear all global session on aws
              this.apiAuthService.forceLogout().subscribe({
                complete: () => {
                  this.redirectToErrorPage(CommonConstant.SESSION_TIME_OUT);
                },
              });
            }
          }
        } else {
          //TODO: page exclude no need navigate page login
          // this.redirectToLoginPage();
        }
      } else if (rootCause.itemNotFound) {
        if (
          SELF_THROW_ERROR.includes(this.router.url) ||
          EXCEPTIONAL_PAGE.includes(this.location.path().split('/')[1])
        ) {
          // Not redirect to page error & fallback to itself show error
          throw error;
        } else {
          
          const state = this.location.getState();
          const navigationId = state && state['navigationId'];
          const error_previous_back = state && state['error_previous_back'];
          // when click button back from item not found page to avoid navigate again
          // it will back to history
          if (error_previous_back) {
            this.sessionStorageService.removeItem(SessionStorageConstant.STATUS_CODE);
            this.sessionStorageService.removeItem(SessionStorageConstant.REDIRECT_URL);
            
            if (navigationId && navigationId <= 2) {
              this.router.navigate(AppRoutingConstant.DASHBOARD, { replaceUrl: true });
            } else {
              this.location.back();
            }
            return;
          }

          if (this.location.path() === this.router.url) {
            this.saveRedirectUrl(this.router.url);
          }
          this.redirectToErrorPage(CommonConstant.ITEM_NOT_FOUND);
        }
      } else if (rootCause.maintenanTime) {
        this.router.navigate([AppRoutingConstant.MAINTENANCE[0]]);
      } else {
        // Show toast when error is Internal Server Error
        this.snackbarService.openSnackBar({
          id: SnackBarItem.ERROR,
        });
      }
    } else {
      console.log('at error.handler, the other exception is uncautch: ', error);
      throw error;
    }
  }

  private redirectToErrorPage(errorConstant: string): void {
    this.dialogService.closeAll();

    if (errorConstant === CommonConstant.SESSION_TIME_OUT) {
      this.authTokenStorageService.clearCredentials();
    }

    this.redirectService.redirectToPageError(errorConstant);
  }

  private redirectToLoginPage(): void {
    this.dialogService.closeAll();

    this.sessionStorageService.removeItem(SessionStorageConstant.STATUS_CODE);
    // force to redirect Login page to login again without call api authenticate check
    this.redirectService.redirectToLogin(true);
  }

  private saveRedirectUrl(url: string): void {
    this.sessionStorageService.setItem(SessionStorageConstant.REDIRECT_URL, url);
  }
}

const CHECK_DEBOUNCE_TIME: number = 1000;
const EXCLUDE_FORCE_LOGOUT = [
  `/${AppRoutingConstant.LOGIN[0]}`,
  `/${AppRoutingConstant.ERROR[0]}`,
  `/${AppRoutingConstant.FORGOT_PASSWORD[0]}`,
];

const SELF_THROW_ERROR = [`/${AppRoutingConstant.FORGOT_PASSWORD[0]}`, `/${AppRoutingConstant.LOGIN[0]}`];
const STATIC_HELP_PAGE = [`/${AppRoutingConstant.PRIVACY_POLICY[0]}`, `/${AppRoutingConstant.TERMS_OF_SERVICE[0]}`];
const EXCEPTIONAL_PAGE = [AppRoutingConstant.EVENT_EVALUATION[0]];

export const EngevaErrorHandlerProvider = [{ provide: ErrorHandler, useClass: EngevaErrorHandler }];
