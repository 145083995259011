import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageConstant } from '../constants/local-storage.constant';
import { Theme } from '../enums/theme.enum';
import { LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private _theme$ = new BehaviorSubject<Theme>(Theme.LIGHT);
  private _isLightTheme = true;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly localStorageService: LocalStorageService
  ) {}

  public setTheme(theme: Theme = Theme.LIGHT): void {
    this._theme$.next(theme);
    this._isLightTheme = theme === Theme.LIGHT;
    this.updateThemeVariables(theme);
    this.localStorageService.setItem(LocalStorageConstant.THEME, theme);
  }

  public setFirstTheme(): void {
    const storageTheme = this.localStorageService.getItem(LocalStorageConstant.THEME) as Theme;
    if (storageTheme) {
      this._theme$.next(storageTheme);
      this._isLightTheme = storageTheme === Theme.LIGHT;
      this.updateThemeVariables(storageTheme);
      return;
    }
    // default theme
    this._theme$.next(Theme.LIGHT);
  }

  public getTheme(): Observable<Theme> {
    return this._theme$.asObservable();
  }

  public isLightTheme(): boolean {
    return this._isLightTheme;
  }

  private updateThemeVariables(theme: Theme): void {
    const toggleTheme = theme === Theme.DARK;
    this.document.body.classList.toggle('dark', toggleTheme);
  }
}
