import { BaseError } from './base-error.interface';

export class MaintainError extends BaseError implements Error {
  constructor(message?: string) {
    super(true, false, false, true);

    this._name = 'MaintainError';

    if (message) {
      this._message = message;
    }
  }
}
