import { GroupItem } from 'src/app/pages/group/interfaces/group.interface';
import { OrganizationItem } from 'src/app/pages/organization/model/organization.interface';
import { BaseItemApiResponse } from '../../../api/interfaces/base-api-response.interface';

export enum AccountType {
  SERVICE_ADMIN = 99,
  MEMBER = 0,
  GROUP_ADMIN = 1,
  ORGANIZATION_ADMIN = 2,
}

/** This is equivalent to type AccountType */
export type Role = keyof typeof AccountType;

export interface User extends BaseItemApiResponse {
  //#region profile user
  user_id: string;
  username?: string;
  email?: string;
  avatar_url?: string;

  // This is fullName
  name?: string;
  first_name?: string;
  last_name?: string;
  role_id?: AccountType;
  //#endregion

  //#region user depends on the value below
  belong_to?: UserBelongTo;
  //#endregion
}

export interface UserBelongTo {
  // #region Organization
  org_id?: OrganizationItem['org_id'];
  name?: OrganizationItem['name'];
  // #endregion

  // #region Groups
  // all array group id that user is belong to
  grp_ids?: string[];

  // as current, in belong_to object `groups` is not returned
  groups?: UserBelongToGroup[];
  // #endregion
}

export interface UserBelongToGroup {
  grp_id?: GroupItem['grp_id'];
  name?: GroupItem['name'];
}
