export enum ApiErrorCode {
  ITEM_NOT_FOUND = 'item_not_found',
  INPUT_DATA_INVALID = 'input_data_invalid',
  REFRESH_TOKEN_INVALID = 'refresh_token_invalid',
  DUPLICATE_NAME = 'duplicate_name',
  OVER_LIMIT = 'over_limit',
  EMAIL_EXISTED = 'email_existed',
  ONE_ORG_MUST_BE_ONE_ORG_ADMIN = 'one_org_must_be_one_org_admin',
  OVER_LIMIT_UNIT = 'over_limit_unit',
  INCORRECT_PASSWORD = 'incorrect_password',
  LIMIT_EXCEEDED = 'limit_exceeded',
  EXPIRED_CODE = 'expired_code',
  CODE_MISMATCH = 'code_mismatch',
  OTHER_EXCEPTION = 'other_exception',
  USERNAME_EXIST_EXCEPTION = 'UsernameExistsException',
  INVALID_PASSWORD_EXCEPTION ='InvalidPasswordException',
}
