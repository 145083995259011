import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './infrastructure/components/main-layout/main-layout.component';
import { AppRoutingConstant } from './infrastructure/constants/app-routing.constant';
import { AuthenticatedGuard } from './infrastructure/guards/authenticated/authenticated.guard';
import { LoginGuard } from './infrastructure/guards/login/login.guard';
import { PermissionGuard } from './infrastructure/guards/permission.guard';
import { AccountType } from './infrastructure/interfaces/user.interface';

const hasPermissionGuard = (permissions: AccountType[]) => {
  return {
    canLoad: [PermissionGuard],
    data: {
      permission: permissions,
    },
  };
};

const routes: Routes = [
  {
    path: '',
    redirectTo: AppRoutingConstant.DASHBOARD[0],
    pathMatch: 'full',
  },
  {
    path: AppRoutingConstant.MAINTENANCE[0],
    loadChildren: () => import('./pages/maintenance/maintenance.module').then(m => m.MaintenanceModule),
  },
  {
    path: AppRoutingConstant.LOGIN[0],
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    canActivate: [LoginGuard],
  },
  {
    path: AppRoutingConstant.FORGOT_PASSWORD[0],
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: AppRoutingConstant.RESET_PASSWORD[0],
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    canActivate: [LoginGuard],
  },
  /** App routing main */
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: AppRoutingConstant.PRIVACY_POLICY[0],
        loadChildren: () =>
          import('./pages/privacy-policy-terms-of-service/privacy-policy-terms-of-service.module').then(
            m => m.PrivacyPolicyTermsOfServiceModule
          ),
      },
      {
        path: AppRoutingConstant.TERMS_OF_SERVICE[0],
        loadChildren: () =>
          import('./pages/privacy-policy-terms-of-service/privacy-policy-terms-of-service.module').then(
            m => m.PrivacyPolicyTermsOfServiceModule
          ),
      },
    ],
  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthenticatedGuard],
    children: [
      {
        path: AppRoutingConstant.DASHBOARD[0],
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path: AppRoutingConstant.MY_PROFILE[0],
        loadChildren: () => import('./pages/my-profile/my-profile.module').then(m => m.MyProfileModule),
        ...hasPermissionGuard([AccountType.MEMBER, AccountType.ORGANIZATION_ADMIN, AccountType.GROUP_ADMIN]),
      },
      {
        path: AppRoutingConstant.ORG[0],
        loadChildren: () => import('./pages/organization/organization.module').then(m => m.OrganizationModule),
        ...hasPermissionGuard([AccountType.SERVICE_ADMIN]),
      },
      {
        path: AppRoutingConstant.GROUP[0],
        loadChildren: () => import('./pages/group/group.module').then(m => m.GroupModule),
        ...hasPermissionGuard([AccountType.ORGANIZATION_ADMIN]),
      },
      {
        path: AppRoutingConstant.USER[0],
        loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule),
        ...hasPermissionGuard([AccountType.SERVICE_ADMIN, AccountType.ORGANIZATION_ADMIN, AccountType.GROUP_ADMIN]),
      },
      {
        path: AppRoutingConstant.EVALUATION_TEMPLATE[0],
        loadChildren: () =>
          import('./pages/evaluation-template/evaluation-template.module').then(m => m.EvaluationTemplateModule),
        ...hasPermissionGuard([AccountType.SERVICE_ADMIN, AccountType.ORGANIZATION_ADMIN]),
      },
      {
        path: AppRoutingConstant.EVALUATION_UNIT[0],
        loadChildren: () =>
          import('./pages/evaluation-template/evaluation-template.module').then(m => m.EvaluationTemplateModule),
        ...hasPermissionGuard([AccountType.SERVICE_ADMIN, AccountType.ORGANIZATION_ADMIN, AccountType.GROUP_ADMIN]),
      },
      // {
      //   path: AppRoutingConstant.EVALUATION_REGISTER[0],
      //   loadChildren: () =>
      //     import('./pages/evaluation-register/evaluation-register.module').then(m => m.EvaluationRegisterModule),
      //   ...hasPermissionGuard([AccountType.MEMBER]),
      // },
      {
        path: AppRoutingConstant.EVENT_EVALUATION[0],
        loadChildren: () =>
          import('./pages/event-evaluation/event-evaluation.module').then(m => m.EventEvaluationModule),
        ...hasPermissionGuard([AccountType.MEMBER]),
      },
      {
        path: AppRoutingConstant.EVALUATION_EVENT_SCHEDULE[0],
        loadChildren: () =>
          import('./pages/evaluation-event-schedule/evaluation-event-schedule.module').then(
            m => m.EvaluationEventScheduleModule
          ),
        ...hasPermissionGuard([AccountType.ORGANIZATION_ADMIN, AccountType.GROUP_ADMIN]),
      },
      {
        path: AppRoutingConstant.EVALUATION_RESULT[0],
        loadChildren: () =>
          import('./pages/evaluation-result/evaluation-result.module').then(m => m.EvaluationResultModule),
      },
      {
        path: AppRoutingConstant.SUMMARY_SETTING[0],
        loadChildren: () => import('./pages/summary-setting/summary-setting.module').then(m => m.SummarySettingModule),
        ...hasPermissionGuard([AccountType.ORGANIZATION_ADMIN, AccountType.GROUP_ADMIN]),
      },
      {
        path: AppRoutingConstant.ERROR[0],
        loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule),
      },
    ],
  },
  {
    path: '**',
    loadChildren: () => import('./pages/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'top',
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
