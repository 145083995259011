import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BreadcrumbComponent } from './infrastructure/components/breadcrumb/breadcrumb.component';
import { HeaderComponent } from './infrastructure/components/header/header.component';
import { LoadingComponent } from './infrastructure/components/loading/loading.component';
import { MainLayoutComponent } from './infrastructure/components/main-layout/main-layout.component';
import { PublicLayoutComponent } from './infrastructure/components/public-layout/public-layout.component';
import { SidebarComponent } from './infrastructure/components/sidebar/sidebar.component';
import { AuthInterceptorProvider } from './infrastructure/providers/auth.interceptor';
import { EngevaErrorHandlerProvider } from './infrastructure/providers/error.handler';
import { LoadingInterceptorProviders } from './infrastructure/providers/loading.interceptor';
import { DynamicMenuListModule } from './modules/dynamic-menu-list/dynamic-menu-list.module';
import { ExpansionPanelModule } from './modules/expansion-panel/expansion-panel.module';
import { NewLottieModule } from './modules/new-lottie/new-lottie.module';
import { SnackBarModule } from './modules/snackbar/snackbar.module';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatSlideToggleModule,
    NewLottieModule,
    ExpansionPanelModule,
    SnackBarModule,
    MatDialogModule,
    DynamicMenuListModule,
    MatMenuModule,
  ],
  declarations: [
    AppComponent,
    PublicLayoutComponent,
    MainLayoutComponent,
    SidebarComponent,
    HeaderComponent,
    LoadingComponent,
    BreadcrumbComponent,
  ],
  providers: [AuthInterceptorProvider, EngevaErrorHandlerProvider, LoadingInterceptorProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
